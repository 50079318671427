export enum SponsorPlacements {
  Dashboard = 'Dashboard',
  Tournament = 'Tournaments',
  TournamentBracket = 'Tournaments.Bracket',
  TournamentOverview = 'Tournaments.Overview',
  TournamentMatches = 'Tournaments.Matches',
  TournamentTeams = 'Tournaments.Teams',
  Seasons = 'Seasons',
  SeasonsView = 'Seasons.View',
  Schedule = 'Schedule',
  Games = 'Games',
  Classrooms = 'Classrooms',
  Gamearena = 'Gamearena',
  GamearenaBrowse = 'Gamearena.Browse',
  Teams = 'Teams',
  TeamsOverview = 'Teams.Overview',
  TeamsMembers = 'Teams.Members',
  TeamsStats = 'Teams.Stats',
  TeamsSchedule = 'Teams.Schedule',
}
export enum InvoiceTypes {
  Stadium = 0,
  CoachingHours = 1,
  Student = 2,
  StudentsPart = 3,
}
export const InvoiceTypesNames = {
  0: 'Stadium',
  1: 'Coaching Hours',
  2: 'Student',
  3: `Student's Part`,
};

export enum UserRoles {
  Admin = 0,
  Ogma = 1,
  Student = 2,
  Coach = 3,
  Guardian = 4,
  Fan = 5,
}
export enum GradeLevels {
  Kindergarten = 0,
  first = 1,
  second = 2,
  third = 3,
  fourth = 4,
  fifth = 5,
  sixth = 6,
  seventh = 7,
  eighth = 8,
  freshman = 9,
  sophomore = 10,
  junior = 11,
  senior = 12,
  NotAStudent = 'no',
}

export const GradeLevelsNames: { [key: string]: string } = {
  0: 'Kindergarten',
  1: '1st Grade',
  2: '2nd Grade',
  3: '3rd Grade',
  4: '4th Grade',
  5: '5th Grade',
  6: '6th Grade',
  7: '7th Grade',
  8: '8th Grade',
  9: '9th Grade (Freshman year)',
  10: '10th Grade (Sophomore year)',
  11: '11th Grade (Junior year)',
  12: '12th Grade (Senior year)',
  no: 'Not a student',
};

export enum UserStatus {
  Active = 1,
  Disabled = 0,
}
export const UserStatusNames: { [key: string]: string } = {
  0: 'Disabled',
  1: 'Active',
};
export enum UserEligibilityStatus {
  NotEligible = 0,
  Eligible = 1,
}
export const UserEligibilityStatusNames: { [key: string]: string } = {
  0: 'Not Eligible',
  1: 'Eligible',
};
export const userRoleNames: { [key: string]: string } = {
  0: 'Admin',
  1: 'Ogma',
  2: 'Student',
  3: 'Coach',
  4: 'Guardian',
  // 5: 'Fan',
};

export enum MatchStatus {
  Upcoming = 0,
  Completed = 1,
  InProgress = 2,
  Overdue = 3,
  Cancelled = 4,
  Forfeit = 5,
  DoubleForfeit = 6,
  ByeMatch = 7,
  RescheduleRequested = 8,
  Benched = 9,
  UpcomingRescheduledFor = 10,
}

export enum StreamingStatus {
  NotStreaming = 0,
  IsStreaming = 1,
}

export enum HighschoolGrades {
  Freshman9thGrade = 1,
  Sophomore10thGrade = 2,
  Junior11thGrade = 3,
  Senior12thGrade = 4,
}
export const schoolStatusNames: { [key: string]: string } = {
  0: 'Active',
  1: 'Disabled',
};
export enum SchoolStatus {
  Active = 0,
  NotActive = 1,
}

export enum OrganizationStatus {
  Disabled = 0,
  Active = 1,
}
export const InvoiceStatusNames: Record<string, string> = {
  0: 'Upcoming',
  1: 'Active',
  2: 'Overdue',
  3: 'Unactive',
  4: 'Processing',
};
export enum InvoiceStatus {
  Upcoming = 0,
  Paid = 1,
  Overdue = 2,
  Unpaid = 3,
  Processing = 4,
}
export enum ScheduledHoursStatus {
  Requested = 0,
  Approved = 1,
  NotApproved = 2,
}
export enum NotificationStatus {
  NotSeen = 0,
  Seen = 1,
}
export enum GameScreenshotType {
  Game = 0,
  Dispute = 1,
  ChatDispute = 2,
}

export enum RescheduleStatus {
  Requested = 0,
  Approved = 1,
  Rejected = 2,
}
export enum DisputeStatus {
  NotChecked = 0,
  Checked = 1,
}

export enum SupportStatus {
  Open = 0,
  Closed = 1,
}

export enum InvoicePaymentType {
  Check = 0,
  Square = 1,
}

export enum ResponseLevel {
  Emergency = 1,
  Urgent = 2,
  Normal = 3,
}

export enum SeverityLevel {
  Outage = 1,
  Critical = 2,
  Urgent = 3,
  Important = 4,
  Monitor = 5,
  Informational = 6,
}

export enum SupportTicketStatus {
  Open = 0,
  Closed = 1,
  Archived = 2,
  InProgress = 3,
}

export const supportTicketStatusName: { [key: string]: string } = {
  0: 'Not Started',
  1: 'Closed',
  2: 'Archived',
  3: 'In Progress',
};

export const supportTicketStatusNameUser: { [key: string]: string } = {
  0: 'Not Assigned',
  1: 'Closed',
  2: 'Archived',
  3: 'In Progress',
};

export enum DateStatus {
  Upcoming = 0,
  Live = 2,
  Completed = 1,
  All = 3,
}

export enum SocketActions {
  Connect = 'WS_CONNECT',
  Disconnect = 'WS_DISCONNECT',
  SendChat = 'WS_SEND_CHAT',
  CheckIn = 'WS_CHECK_IN',
  CheckOut = 'WS_CHECK_OUT',
  DeleteClassroomMessage = 'WS_DELETE_CLASSROOM_CHAT_MSG',
  DeleteMatchMessage = 'WS_DELETE_MATCH_CHAT_MSG',
  DeleteTeamMessage = 'WS_DELETE_TEAM_MSG',
  ClassroomConnect = 'WS_CLASSROOM_CONNECT',
  ClassroomDisconnect = 'WS_CLASSROOM_DISCONNECT',
  ClassroomSendChat = 'WS_CLASSROOM_SEND_CHAT',

  ConnectSeasonWeek = 'WS_CONNECT_SEASON_WEEK',
  DisconnectSeasonWeek = 'WS_DISCONNECT_SEASON_WEEK',
}

export enum SocketMessages {
  // match
  Connect = 'connect',
  Match = 'match',
  Dispute = 'Dispute',
  Reschedules = 'reschedules',
  TeamChat = 'team-chat',
  RoomChat = 'room-chat',
  Chat = 'chat',
  CheckIn = 'check-in',
  CheckOut = 'check-out',
  MatchGame = 'MatchGame',
  UpdateMatchGame = 'UpdateMatchGame',
  Disconnect = 'disconnect',
  JoinRoom = 'join-room',
  EmitDeleteChatMsg = 'classroom-chat-delete',
  DeleteChatMsgMatchAll = 'delete-chat-msg-match-all',
  DeleteTeamMsg = 'delete-chat-msg-match-team',

  // season week
  ConnectSeasonWeek = 'connect-season-week',
  Matches = 'matches',
  Teams = 'teams',
}

export enum TournamentProgressStatus {
  Upcoming = 0,
  Completed = 1,
  Ongoing = 2,
}
export const TournamentStatusNames: Record<string, string> = {
  0: 'Upcoming',
  1: 'Completed',
  2: 'Ongoing',
};
export enum SeasonProgressStatus {
  Upcoming = 0,
  Completed = 1,
  Ongoing = 2,
}

export const allStates = [
  { id: 'Alabama', name: 'Alabama' },
  { id: 'Alaska', name: 'Alaska' },
  { id: 'Arizona', name: 'Arizona' },
  { id: 'Arkansas', name: 'Arkansas' },
  { id: 'California', name: 'California' },
  { id: 'Colorado', name: 'Colorado' },
  { id: 'Connecticut', name: 'Connecticut' },
  { id: 'Delaware', name: 'Delaware' },
  { id: 'Florida', name: 'Florida' },
  { id: 'Georgia', name: 'Georgia' },
  { id: 'Hawaii', name: 'Hawaii' },
  { id: 'Idaho', name: 'Idaho' },
  { id: 'Illinois', name: 'Illinois' },
  { id: 'Indiana', name: 'Indiana' },
  { id: 'Iowa', name: 'Iowa' },
  { id: 'Kansas', name: 'Kansas' },
  { id: 'Kentucky', name: 'Kentucky' },
  { id: 'Louisiana', name: 'Louisiana' },
  { id: 'Maine', name: 'Maine' },
  { id: 'Maryland', name: 'Maryland' },
  { id: 'Massachusetts', name: 'Massachusetts' },
  { id: 'Michigan', name: 'Michigan' },
  { id: 'Minnesota', name: 'Minnesota' },
  { id: 'Mississippi', name: 'Mississippi' },
  { id: 'Missouri', name: 'Missouri' },
  { id: 'Montana', name: 'Montana' },
  { id: 'Nebraska', name: 'Nebraska' },
  { id: 'Nevada', name: 'Nevada' },
  { id: 'New Hampshire', name: 'New Hampshire' },
  { id: 'New Jersey', name: 'New Jersey' },
  { id: 'New Mexico', name: 'New Mexico' },
  { id: 'New York', name: 'New York' },
  { id: 'North Carolina', name: 'North Carolina' },
  { id: 'North Dakota', name: 'North Dakota' },
  { id: 'Ohio', name: 'Ohio' },
  { id: 'Oklahoma', name: 'Oklahoma' },
  { id: 'Oregon', name: 'Oregon' },
  { id: 'Pennsylvania', name: 'Pennsylvania' },
  { id: 'Rhode Island', name: 'Rhode Island' },
  { id: 'South Carolina', name: 'South Carolina' },
  { id: 'South Dakota', name: 'South Dakota' },
  { id: 'Tennessee', name: 'Tennessee' },
  { id: 'Texas', name: 'Texas' },
  { id: 'Utah', name: 'Utah' },
  { id: 'Vermont', name: 'Vermont' },
  { id: 'Virginia', name: 'Virginia' },
  { id: 'Washington', name: 'Washington' },
  { id: 'Washington, D.C', name: 'Washington, D.C' },
  { id: 'West Virginia', name: 'West Virginia' },
  { id: 'Wisconsin', name: 'Wisconsin' },
  { id: 'Wyoming', name: 'Wyoming' },
];

export enum GameIsArchived {
  No = 0,
  Yes = 1,
}

export enum Genders {
  Man = 1,
  Woman = 2,
  Other = 3,
  NotToDisclose = 4,
}
export const genderNames: Record<string, string> = {
  1: 'Man',
  2: 'Woman',
  3: 'Additional gender category/identity not listed',
  4: 'Choose not to disclose',
};
export const gendersOptions = [
  {
    id: 1,
    name: genderNames[Genders.Man],
  },
  {
    id: 2,
    name: genderNames[Genders.Woman],
  },
  {
    id: 3,
    name: genderNames[Genders.Other],
  },
  {
    id: 4,
    name: genderNames[Genders.NotToDisclose],
  },
];

export enum SchemaMessages {
  EndDate = 'End date cannot be before start date',
  DateShouldNotBeInThePast = 'Date and time should not be in the past',
  SelectedDateMusBeFromToday = 'Selected date must be from today',
  SeasonWeekDate = 'Selected date must be after season week start date',
  TournametStartDate = 'Selected date must be after tournament start date',
  AddMatchToWeekDifferentTeam = 'Away team should be different from home team!',
}

export const TicketApprovalStatus = {
  UserNotApproved: 0,
  AdminNotApproved: 0,
  UserApproved: 1,
  AdminApprove: 1,
};

export const priorityLevels: Record<string, string> = {
  1: 'P1',
  2: 'P2',
  3: 'P3',
};

export enum MatchFormats {
  BestOf = 1,
  FirstTo = 2,
  Minimum = 3,
  Chess = 4,
}

export const matchFormatNames: Record<string, string> = {
  [MatchFormats.BestOf]: 'Best Of',
  [MatchFormats.FirstTo]: 'First To',
  [MatchFormats.Minimum]: 'Minimum',
  [MatchFormats.Chess]: 'Chess To',
};
export enum MatchGameStatus {
  Open,
  Closed,
  Review,
}

export const rescheduleStatuses: Record<string, string> = {
  0: 'Requested',
  1: 'Approved',
  2: 'Rejected',
};

export enum rescheduleStatus {
  Requested = 0,
  Approved = 1,
  Rejected = 2,
}

export const rescheduleRoles: Record<string, string> = {
  0: 'Admin',
  1: 'Ogma',
  2: 'Student',
};

export enum SeasonWeekStatus {
  NotStarted,
  Open,
  Closed,
  Published,
}

export enum TicketStatusTime {
  Open = 0,
  Closed = 1,
  Archived = 2,
  InProgressT1 = 3,
  ToDo = 4,
  InProgressT2 = 5,
  MonitorReview = 6,
}

export enum UserSupportTabStatus {
  Open = 1,
  Closed = 2,
  Archived = 3,
}

export const ForfeitHistoryActions: { [key: string]: string } = {
  1: 'Forfeit',
  2: 'Unforfeit',
  3: 'Double forfeit',
  4: 'Double unforfeit',
};

export enum ProgramTitles {
  DroneRacing = 'Drone Racing',
  Esports = 'Esports',
}

export const ManualTagPrefix = '#';

export enum WarningMessages {
  CanNotAddSubsToOnePlayerTeams = 'No substitutes can be added to a one-player team.',
}

export enum MatchGenerationTypes {
  RoundRobin = 1,
  Swiss = 2,
}

export enum SeasonTypes {
  classic = 1,
  leaderboard = 2,
}

export const StageTypes = {
  RoundRobin: { id: 1, displayText: 'Round Robin (All play all)' },
  Tournament: { id: 2, displayText: 'Tournament' },
  SkillSync: { id: 3, displayText: 'Skill Sync' },
} as { [key: string]: { id: number; displayText: string } };

export const SeasonStageTeamSelectionMethod = {
  AllTeams: { id: 1, displayText: 'All Teams' },
  SelectTopTeams: { id: 2, displayText: 'Select Top Teams' },
  ManuallySelectTeams: { id: 3, displayText: 'Manually Select Teams' },
};

export enum SeasonStageTypes {
  RoundRobin = 1,
  Tournament = 2,
  SkillSync = 3,
}

export enum Games {
  Fortnite = 'Fortnite',
}
