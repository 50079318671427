import { AiOutlineCamera } from 'react-icons/ai';
import { Navigate, NavLink, Outlet, useParams } from 'react-router-dom';
import { AvatarImage } from '../../../components/avatar-image';
import {
  useChangeSchoolProfilePhotoMutation,
  useGetSchoolByIdQuery,
  useUploadBannerMutation,
} from '../../../slices/schoolsApiSlice';
import { SchoolInfo } from './components/school-info';
import CanIView from '../../../components/can-i-view/can-i-view';
import BannerImage from '../../../components/table-td/banner-image';
import { ChangeEvent, useEffect, useState } from 'react';
import { SchoolModel } from '../../../model/school/school-model';
import { CustomError } from '../../../utils/custom-types';

function ViewSchool() {
  const [model, setModel] = useState<SchoolModel>();
  const [changeSchoolProfilePhoto, { isError, isLoading, isSuccess }] =
    useChangeSchoolProfilePhotoMutation();
  const [uploadBanner, { isError: bnIsError, isLoading: bnIsLoading, isSuccess: bnIsSuccess }] =
    useUploadBannerMutation();
  const param = useParams();
  function handleFileChange(event: ChangeEvent<HTMLInputElement>, type: 'banner' | 'avatar') {
    const file = event.target.files ? event.target.files[0] : '';
    const formData = new FormData();
    formData.append('file', file);
    if (type === 'avatar')
      changeSchoolProfilePhoto({ photo: formData, id: param.id })
        .unwrap()
        .then((res) => console.log(res))
        .catch((e) => console.log(e));
    else {
      uploadBanner({ body: formData, id: param.id ?? '' });
    }
  }

  const buttonList = [
    // {
    //   name: 'Overview',
    //   link: `/schools/view/${param.id}/`,
    // },
    {
      name: 'Students',
      link: './students',
    },
    {
      name: 'Teams',
      link: './teams',
    },
  ];

  const {
    error,
    data: schoolData,
    isLoading: schoolIsLoading,
    isError: schoolIsError,
    isSuccess: schoolIsSuccess,
  } = useGetSchoolByIdQuery(param.id);

  useEffect(() => {
    if (!schoolData) return;
    setModel(new SchoolModel(schoolData));
  }, [schoolData]);
  if (schoolIsError || (schoolIsSuccess && Object.keys(schoolData).includes('clientVersion')))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (schoolIsLoading) return <p>Loading...</p>;

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex justify-between pt-10 flex-col rounded-lg bg-white w-full h-[235px] '>
        <div className='flex relative justify-between  items-center rounded-tl-xl gap-5 rounded-tr-xl w-full min-h-[150px] bg-white overflow-hidden'>
          <div className='group absolute w-full h-full top-0 left-0'>
            <BannerImage source={model?.banner ? model?.banner : ''} />

            <CanIView component='schools.changePhoto'>
              <label className='group-hover:opacity-100 opacity-0 flex bg-black bg-opacity-80 absolute justify-center items-center w-full h-full  text-white left-0 top-0 cursor-pointer'>
                <AiOutlineCamera size={30} />
                <input
                  type='file'
                  accept='image/png, image/gif, image/jpeg'
                  onChange={(e) => handleFileChange(e, 'banner')}
                  className='hidden mr-12'
                />
              </label>
            </CanIView>
          </div>
          <div className='flex items-center gap-5 p-4 bg-black bg-opacity-60 border-1 border-gray-500 rounded-lg z-10 ml-10 w-[400px]'>
            <div className='group cursor-pointer flex relative w-[100px] aspect-square rounded-full overflow-hidden justify-center items-center '>
              {isLoading ? (
                <p>Loading ...</p>
              ) : (
                <AvatarImage size='xxl' source={schoolData?.image} />
              )}

              <CanIView component='schools.changePhoto'>
                <label className='cursor-pointer text-white  w-full bg-black bg-opacity-80 h-full absolute top-0 right-0 left-0 bottom-0 m-auto z-10 hidden group-hover:flex justify-center items-center  '>
                  <AiOutlineCamera />
                  <input
                    type='file'
                    accept='image/png, image/gif, image/jpeg'
                    onChange={(e) => handleFileChange(e, 'avatar')}
                    className='hidden mr-12'
                  />
                </label>
              </CanIView>
            </div>
            <SchoolInfo
              model={model as SchoolModel}
              name={model?.name}
              organization={model?.org_name ?? '-'}
              members={model?.user_count ?? 0}
              status={model?.status_name ?? '-'}
            />
          </div>

          {/* <div className="flex justify-evenly flex-[3] items-center">
            <GameBox count={124} description="Completed matches" />{" "}
            <GameBox count={124} description="Completed matches" />{" "}
            <GameBox count={124} description="Completed matches" />{" "}
          </div> */}
        </div>
        <div className='flex gap-2 p-2 bg-white '>
          {buttonList.map((b) => (
            <NavLink key={b.link} to={b.link} className='settingsLink'>
              <div> {b.name} &nbsp;</div>
            </NavLink>
          ))}
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default ViewSchool;
