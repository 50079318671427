import { ErrorMessage, Form, Formik, FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { FormInput } from '../../../components/form-input/form-input';
import { OutsideClick } from '../../../components/outside-click';
import { SubmitButton } from '../../../components/submit-button';
import {
  useDoubleForfeitMatchMutation,
  useForfeitMatchMutation,
  useGetSingleMatchInfoQuery,
  useGetAllMatchesByTournamentRoundQuery,
} from '../../../slices/matchApiSlice';
import { CustomError } from '../../../utils/custom-types';
import ModalHeader from '../../../components/modal-header';
import CanIView from '../../../components/can-i-view/can-i-view';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { UserRoles } from '../../../utils/constants';
import { ForfeitValidationSchema } from '../../../features/authentication/yupschema';
import { selectMatchData } from '../../../slices/matchSlice';
import { MatchStatus } from '../../../utils/constants';
interface ITeam {
  id: number;
}
const ForfeitMatch = () => {
  const user = useSelector(selectUser);
  const [selectedTeam, setSelectedTeam] = useState();
  const [canWeForfeit, setCanWeForfeit] = useState(0);
  const param = useParams();
  const { error, data, isLoading, isError } = useGetSingleMatchInfoQuery(param.id);
  const [forfeitMatch, { data: foData, isLoading: foIsLoading, isSuccess: foIsSuccess }] =
    useForfeitMatchMutation();
  const [doubleForfeitMatch, { isLoading: dblForfeitMatchLoading, isSuccess: dblForfeitSuccess }] =
    useDoubleForfeitMatchMutation();
  const [doubleForfeitCheck, setDoubleForfeitCheck] = useState<boolean>(false);

  const ogmaPartOf = [];
  let isUserOGMAofTeam = 0;
  if (user?.roles === UserRoles.Ogma) {
    const home_team = data?.home_team;
    const away_team = data?.away_team;

    const homeOgmas = [
      home_team?.[0]?.organizations?.map((org: any) => org.user?.id),
      home_team?.[0]?.School?.organization?.user?.id,
      ...(home_team?.[0]?.organizations?.map((org: any) =>
        org.ogmas?.map((o: { id: number }) => o.id),
      ) ?? []),
      ...(home_team?.[0]?.School?.organization?.ogmas?.map((o: { id: number }) => o.id) ?? []),
    ].flat();

    const awayOgmas = [
      away_team?.[0]?.organizations?.map((org: any) => org.user?.id),
      away_team?.[0]?.School?.organization?.user?.id,
      ...(away_team?.[0]?.organizations?.map((org: any) =>
        org.ogmas?.map((o: { id: number }) => o.id),
      ) ?? []),
      ...(away_team?.[0]?.School?.organization?.ogmas?.map((o: { id: number }) => o.id) ?? []),
    ].flat();

    if (homeOgmas?.includes(user?.id) && awayOgmas?.includes(user?.id)) {
      isUserOGMAofTeam = isUserOGMAofTeam + 1;
      const arr = [
        { id: home_team[0]?.id, name: home_team[0]?.teamName },
        { id: away_team[0]?.id, name: away_team[0]?.teamName },
      ];
      ogmaPartOf.push(...arr);
    } else if (homeOgmas?.includes(user?.id) && !awayOgmas?.includes(user?.id)) {
      isUserOGMAofTeam = isUserOGMAofTeam + 1;
      const arr = [{ id: home_team[0]?.id, name: home_team[0]?.teamName }];
      ogmaPartOf.push(...arr);
    } else if (!homeOgmas?.includes(user?.id) && awayOgmas?.includes(user?.id)) {
      isUserOGMAofTeam = isUserOGMAofTeam + 1;
      const arr = [{ id: away_team[0]?.id, name: away_team[0]?.teamName }];
      ogmaPartOf.push(...arr);
    }
  }

  const matchData = useSelector(selectMatchData);
  const { data: tournamentRoundData } = useGetAllMatchesByTournamentRoundQuery(matchData.id);
  useEffect(() => {
    if (matchData.tournament_round_id) {
      setCanWeForfeit(0);
      tournamentRoundData?.match?.forEach((m: any) => {
        if (m.status === MatchStatus.Cancelled || m.status === MatchStatus.DoubleForfeit) {
          setCanWeForfeit((prevCount) => prevCount + 1);
        }
      });
    }
  }, [tournamentRoundData]);
  const formInputs = [
    {
      mainTitle: 'Forfeit',
      name: 'team_id',
      label: 'Select team to forfeit',
      type: 'text',
      placeholder: 'Team',
      isTrue: true,
      isSelect: true,
      options:
        data && user?.roles !== UserRoles.Ogma
          ? [
              { id: data.home_team[0].id, name: data.home_team[0].teamName },
              {
                id: data.away_team[0].id,
                name: data.away_team[0].teamName,
              },
            ]
          : data && user?.roles === UserRoles.Ogma
          ? ogmaPartOf
          : [],
    },
  ];

  useEffect(() => {
    if (doubleForfeitCheck) {
      setSelectedTeam(undefined);
    }
  }, [doubleForfeitCheck]);

  function handleSubmit(values: FormikValues) {
    const forfeitReason = values?.reason;
    if (doubleForfeitCheck) {
      doubleForfeitMatch({ id: param?.id, body: { reason: forfeitReason } });
      return;
    }

    const getId = formInputs
      ?.flatMap((op: any) => op?.options)
      ?.find((team: ITeam) => team.id === Number(selectedTeam));

    forfeitMatch({
      id: param.id,
      body: {
        // team: formInputs[0].options[0].id === Number(selectedTeam) ? 'home' : 'away',
        teamId: getId?.id,
        reason: forfeitReason,
      },
    });
  }

  if (foIsSuccess || dblForfeitSuccess) return <Navigate to='../' />;
  if (user.roles !== UserRoles.Admin && !(user.roles === UserRoles.Ogma && isUserOGMAofTeam > 0)) {
    return <Navigate to='/went-wrong' state={{ message: 'You cannot forfeit a match' }} />;
  }
  if (isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (isLoading) return <p>Loading...</p>;
  return (
    <>
      <OutsideClick />
      <Formik
        initialValues={{ reason: '' }}
        onSubmit={handleSubmit}
        validationSchema={user?.roles === UserRoles.Ogma ? ForfeitValidationSchema : null}
      >
        {({ errors, setFieldValue }) => (
          <Form>
            <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-3xl w-[300px] md:w-[600px] max-w-3xl max-h-screen'>
              <div className='w-full gap-5 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none max-h-full overflow-auto'>
                <ModalHeader name='Forfeit Match' />

                <CanIView component='view-match.dbl-forfeit'>
                  {canWeForfeit !== tournamentRoundData?.match?.length ? (
                    <div className='px-6'>
                      <div className='flex items-center gap-1.5 hover:bg-slate-50 cursor-pointer'>
                        <input
                          type='checkbox'
                          id='doubleForfeitCheck'
                          onChange={() => setDoubleForfeitCheck(!doubleForfeitCheck)}
                          checked={doubleForfeitCheck}
                          className='h-4 w-4 cursor-pointer'
                        />
                        <label htmlFor={'doubleForfeitCheck'} className='flex-1 cursor-pointer'>
                          Double forfeit
                        </label>
                      </div>
                      <hr />
                    </div>
                  ) : (
                    <></>
                  )}
                </CanIView>
                {!doubleForfeitCheck ? (
                  <div className='relative px-6 flex flex-col'>
                    {formInputs.map((input: any, i: number) => (
                      <React.Fragment key={i}>
                        {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                          <>
                            <div className='flex flex-col'>
                              <div className='flex flex-wrap gap-1'>
                                {formInputs
                                  .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                                  .map((inp: any, i: any) => (
                                    <React.Fragment key={i}>
                                      <div
                                        className={inp.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'}
                                      >
                                        <FormInput setFieldValue={setSelectedTeam} {...inp} />
                                      </div>
                                    </React.Fragment>
                                  ))}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                    {selectedTeam && (
                      <h2 className='text-xl text-black'>
                        Are you sure you want to forfeit{' '}
                        <span className='font-bold text-black break-words'>
                          '{formInputs[0].options.filter((o) => o.id === +selectedTeam)[0].name}'
                        </span>
                      </h2>
                    )}
                  </div>
                ) : null}
                {(selectedTeam || doubleForfeitCheck) && (
                  <>
                    <div className='px-6 flex flex-col'>
                      <div className='p-3 border border-[#E5E7EB] w-full rounded-lg h-[140px] focus:border-[#333]'>
                        <label
                          htmlFor='forfeitReason'
                          className='text-[#6B7280] text-sm font-semibold'
                        >
                          Forfeit Reason
                        </label>
                        <textarea
                          className='pt-1 text-md border-none outline-none resize-none w-full placeholder-black'
                          placeholder='Please enter reason for forfeit.'
                          id='forfeitReason'
                          name='reason'
                          onChange={(e) => setFieldValue('reason', e.target.value)}
                        ></textarea>
                      </div>
                      {errors?.reason && (
                        <ErrorMessage
                          component='div'
                          name='reason'
                          className='text-red-400 text-xs'
                        />
                      )}
                    </div>
                    <div className='flex px-6 items-center justify-end gap-2 border-slate-200 rounded-b sticky bottom-0 bg-white'>
                      <SubmitButton isLoading={foIsLoading || dblForfeitMatchLoading} />
                      <Link
                        className='w-1/5 my-12 text-center py-1 bg-white text-black shadow-lg font-semibold rounded-lg'
                        to='../'
                      >
                        Cancel
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForfeitMatch;
