import Bracket from '../../../routes/tournaments/routes/torunament-bracket/Bracket';
import NewTeamModal from '../../../features/listview/NewModals/NewTeamModal';
import { Navigate, RouteObject } from 'react-router-dom';
import MyAccount from '../../../features/Settings/AdminSettings/MyAccount';
import NotificationSettings from '../../../features/Settings/AdminSettings/Notifications';
import SecuritySettings from '../../../features/Settings/AdminSettings/Security';
import Settings from '../../../features/Settings/AdminSettings/Settings';
import Schools from '../../../features/listview/Schools';
import Teams from '../../../features/listview/teams/Teams';
import Users from '../../../features/listview/users/Users';
import AllClassrooms from '../../../routes/classrooms/all-classrooms';
import CoachProfile from '../../../routes/coach-profile/coach-profile';
import GameArena from '../../../routes/gamearena/gamearena';
import ConnectTwitch from '../../../routes/gamearena/routes/connect-twitch/connect-twitch';
import GamearenaBrowse from '../../../routes/gamearena/routes/gamearena-browse/gamearena-browse';
import GameArenaLive from '../../../routes/gamearena/routes/gamearena-live/gamearena-live';
import GamearenaStreamerPage from '../../../routes/gamearena/routes/gamearena-streamer-page/gamearena-streamer-page';
import GaRecentVideoRoute from '../../../routes/gamearena/routes/gamearena-video/ga-recent-video-route';
import AllGames from '../../../routes/games/AllGames';
import GameOverview from '../../../routes/games/overview/GameOverview';
import GameAllTeams from '../../../routes/games/teams/GameAllTeams';
import { MatchDetailsReschedule } from '../../../routes/match/components/match-details-reschedule';
import EditGameResults from '../../../routes/match/edit-game-results/edit-game-results';
import ViewMatch from '../../../routes/match/view-match';
import OGMADashboard from '../../../routes/ogma-dashboard/ogma-dashboard';
import InviteStudentsOgma from '../../../routes/ogma-dashboard/route/invite-students/invite-students-ogma';
import OgmaSchoolSubscriptions from '../../../routes/ogma-school-subscriptions/ogma-school-subscriptions';
import OgmaSubPayments from '../../../routes/ogma-school-subscriptions/route/ogma-sub-payments';
import OgmaSubSchoolInfo from '../../../routes/ogma-school-subscriptions/route/ogma-sub-school-info';
import OrganizationSchools from '../../../routes/organizations/view-organization/routes/organization-schools';
import OrganizationTeams from '../../../routes/organizations/view-organization/routes/organization-teams';
import ViewOrganization from '../../../routes/organizations/view-organization/view-organization';
import Classrooms from '../../../routes/profiles/coach-profile/classrooms/classrooms';
//import CoachOverview from '../../../routes/profiles/coach-profile/overview/coach-overview';
import TrainingContent from '../../../routes/profiles/coach-profile/training-content/training-content';
import ViewCoach from '../../../routes/profiles/coach-profile/view-coach';
import UserStats from '../../../routes/profiles/view-profiles/user-stats';
import ViewUser from '../../../routes/profiles/view-profiles/view-user';
import { AllSchedule } from '../../../routes/schedule/AllSchedules';
import SchoolStudents from '../../../routes/schools/view-school/routes/school-students/components/SchoolStudents';
import SchoolTeams from '../../../routes/schools/view-school/routes/school-teams/components/SchoolTeams';
import ViewSchool from '../../../routes/schools/view-school/view-school';
import AllSeasons from '../../../routes/seasons/routes/all/all-seasons';
import { Seasons } from '../../../routes/seasons/seasons';
import SubscriptionRedirect from '../../../routes/subscriptions/subscriptions-redirect';
import TeamViewStats from '../../../routes/teams/view-team/routes/stats/team-view-stats';
import AddStudent from '../../../routes/teams/view-team/routes/team-members/components/add-student';
import TeamMembers from '../../../routes/teams/view-team/routes/team-members/team-members';
import TeamSchedule from '../../../routes/teams/view-team/routes/team-schedule/team-schedule';
import ViewTeam from '../../../routes/teams/view-team/view-team';
import AllTournaments from '../../../routes/tournaments/routes/all/all-tournaments';
import Tournaments from '../../../routes/tournaments/tournaments';
import ViewClassroom from '../../../routes/view-classroom/view-classroom';
import ViewSeason from '../../../routes/view-season/view-season';
import { TournamentMatches } from '../../../routes/view-tournament/tournament-matches';
import TournamentOverview from '../../../routes/view-tournament/tournament-overview';
import TournamentStandings from '../../../routes/view-tournament/tournament-standings';
import TournamentTeams from '../../../routes/view-tournament/tournament-teams';
import Tournament from '../../../routes/view-tournament/view-tournament';
import Dashboard from '../../Dashboard';
import Calendar from '../../../routes/calendar-view/calendar';
import AllNotifications from '../../../routes/notifications/all-notification';
import ViewReschedules from '../../../routes/match/view-reschedules/view-reschedules';
import ClassroomsScheduleHours from '../../../routes/classrooms/route/classrooms-schedule-hours';
import ViewLesson from '../../../routes/view-lesson/view-lesson';
import PreviewClassroom from '../../../routes/preview-classroom/preview-classroom';
import Lessons from '../../../routes/profiles/coach-profile/training-content/Lessons';
import Support from '../../../routes/support/support';
import CreateNewTicket from '../../../routes/support/components/createNewTicket';
import ViewMyTickets from '../../../routes/support/components/viewMyTickets';

import SuccessSettings from '../../../routes/settings/success-settings';
import SupportOtherRolesView from '../../../routes/support/support-other-roles';
import DisconnectTwitch from '../../../routes/gamearena/routes/disconnect-twitch/disconnect-twitch';
import OgmaInvitedUsers from '../../../routes/ogma-invited-users/ogma-invited-users';
import ViewScreenshot from '../../../routes/match/view-screenshot/ViewScreenshot';
import ViewTicketUser from '../../../routes/support/view-ticket-user';
import ApproveTicketUser from '../../../routes/support/approve-ticket-user';
import UserNotApproveTicket from '../../../routes/support/user-not-approve-ticket';
import ArchiveUser from '../../../routes/archive-user/archive-user';
import CheckInTeam from '../../../routes/match/check-in/check-in-team';
import EditReschedule from '../../../routes/match/view-reschedules/edit-reschedule';
import CancelReschedule from '../../../routes/match/view-reschedules/cancel-reschedule';
import SeasonWeekScore from '../../../routes/view-season/routes/season-week-score/season-week-score.route';
import OgmaInvitedUsersCancel from '../../../routes/ogma-invited-users/ogma-invited-users-cancel';
import Usernames from '../../../features/Settings/AdminSettings/Usernames';
import AddGameAccount from '../../../routes/games/overview/add-game/AddGameAccount';
import DeleteGameAccount from '../../../routes/games/overview/add-game/DeleteGameAccount';
import ForfeitMatch from '../../../routes/match/forfeit/forfeit-match';
import ViewForfeits from '../../../routes/match/forfeit/view-forfeits';
import DisputeMatch from '../../../routes/match/dispute-match/dispute-match';
import DeleteUser from '../../../routes/delete-user/delete-user';
import NewSchoolInviteLink from '../../../routes/school-invite/new-school-invite-link';
import AddSeasonTeams from '../../../routes/seasons/routes/all/routes/add-teams/add-season-teams';
import EditSeasonTeams from '../../../routes/seasons/routes/all/routes/edit-teams/edit-season-teams';
import TeamSeasonSelection from '../../../features/listview/teams/TeamSeasonSelection';
import CheckOutTeam from '../../../routes/match/check-out/check-out-team';
import DisputeMatchChat from '../../../routes/match/dispute-match-chat/dispute-match-chat';
import JoinWeek from '../../../routes/seasons/routes/all/routes/join-week/join-week';

export const ogmaProtectedRoutes: RouteObject[] = [
  {
    path: '',
    element: <Navigate to='./dashboard' />,
  },
  {
    path: 'calendar',
    element: <Calendar />,
  },
  {
    path: 'subscriptions',
    element: <SubscriptionRedirect />,
  },
  {
    path: '/subscriptions/ogma',
    element: <OgmaSchoolSubscriptions />,
    children: [
      {
        path: 'school/:id',
        element: <OgmaSubSchoolInfo />,
        children: [],
      },
      {
        path: 'school/:id/:invoice',
        element: <OgmaSubPayments />,
      },
    ],
  },
  {
    path: 'dashboard',
    element: <Dashboard />,
  },
  {
    path: 'notifications',
    element: <AllNotifications />,
  },
  {
    path: '/dashboard/ogma',
    element: <OGMADashboard />,
    children: [
      {
        path: 'invite-students',
        element: <InviteStudentsOgma />,
        children: [
          {
            path: 'new-school-link/:id',
            element: <NewSchoolInviteLink />,
          },
        ],
      },
    ],
  },
  {
    path: 'gamearena',
    element: <GameArena />,
    children: [
      {
        path: 'connect',
        element: <ConnectTwitch />,
      },
      {
        path: 'disconnect',
        element: <DisconnectTwitch />,
      },
      {
        path: 'video/:vid',
        element: <GaRecentVideoRoute />,
      },
    ],
  },
  {
    path: 'gamearena/live/:id',
    element: <GameArenaLive />,
  },

  {
    path: 'gamearena/streamer/:id',
    element: <GamearenaStreamerPage />,
    children: [
      {
        path: 'video/:vid',
        element: <GaRecentVideoRoute />,
      },
    ],
  },
  {
    path: 'gamearena/browse',
    element: <GamearenaBrowse />,
  },
  {
    path: 'match/:id',
    element: <ViewMatch />,
    children: [
      // {
      //   path: 'submit',
      //   element: <SubmitMatchResults />,
      // },
      {
        path: 'dispute-chat',
        element: <DisputeMatchChat />,
      },
      {
        path: 'dispute',
        element: <DisputeMatch />,
      },
      // {
      //   path: 'edit',
      //   element: <EditMatch />,
      // },
      // {
      //   path: 'edit-result',
      //   element: <EditMatchResults />,
      // },
      {
        path: 'forfeit',
        element: <ForfeitMatch />,
      },
      {
        path: 'reschedule',
        element: <MatchDetailsReschedule />,
      },
      {
        path: 'view-reschedules',
        element: <ViewReschedules />,
        children: [
          {
            path: 'edit/:rescheduleId',
            element: <EditReschedule />,
          },
          {
            path: 'cancel/:rescheduleId',
            element: <CancelReschedule />,
          },
        ],
      },
      {
        path: 'view-forfeits',
        element: <ViewForfeits />,
      },
      {
        path: 'view-forfeits',
        element: <ViewForfeits />,
      },
      {
        path: 'edit-game-results/:gid',
        element: <EditGameResults />,
      },
      {
        path: 'view-screenshot/:gid',
        element: <ViewScreenshot />,
      },
      {
        path: 'check-in/:teamId/',
        element: <CheckInTeam />,
      },
      {
        path: 'check-out/:teamId/',
        element: <CheckOutTeam />,
      },
      {
        path: 'check-in/all/:teamId1/:teamId2',
        element: <CheckInTeam />,
      },
      {
        path: 'check-out/all/:teamId1/:teamId2',
        element: <CheckOutTeam />,
      },
    ],
  },
  {
    path: 'coach',
    element: <CoachProfile />,
  },
  // {
  //   path: '/tournaments/by-game/:id',
  //   element: <TournamentsByGame />,
  // },
  // { path: '/seasons/by-game/:id', element: <SeasonsByGame /> },
  // {
  //   path: '/tournaments/by-division/:id',
  //   element: <TournamentsByDivision />,
  // },
  // {
  //   path: '/seasons/by-division/:id',
  //   element: <SeasonByDivision />,
  // },
  {
    path: 'seasons/view/:id',
    element: <ViewSeason />,
    children: [
      // {
      //   path: 'edit-teams',
      //   element: <EditSeasonTeams />,
      // },
      // {
      //   path: 'generate-matches/:weekid',
      //   element: <GenerateMatch />,
      // },
      // {
      //   path: 'new-week',
      //   element: <NewSeasonWeek />,
      // },
      // {
      //   path: 'edit-season',
      //   element: <EditSeason />,
      // },
      {
        path: 'score/:matchId',
        element: <SeasonWeekScore />,
      },
      {
        path: 'add-teams',
        element: <AddSeasonTeams />,
      },
      {
        path: 'edit-teams',
        element: <EditSeasonTeams />,
      },
      {
        path: 'join-week',
        element: <JoinWeek />,
      },
    ],
  },
  {
    path: 'seasons',
    element: <Seasons />,
    children: [
      {
        path: 'all',
        element: <AllSeasons />,
        children: [
          // {
          //   path: 'new',
          //   element: <NewSeason />,
          // },
          // {
          //   path: 'edit/:id',
          //   element: <EditSeason />,
          // },
          // {
          //   path: 'archive/:id',
          //   element: <ArchiveSeason />,
          // },
        ],
      },
      // {
      //   path: 'division',
      //   element: <CategoryDivisions />,
      //   children: [
      //     // {
      //     //   path: 'new',
      //     //   element: <NewSeasonDivision />,
      //     // },
      //     // {
      //     //   path: 'edit/:id',
      //     //   element: <EditSeasonDivision />,
      //     // },
      //   ],
      // },
      // {
      //   path: 'subdivision',
      //   element: <Subdivisions />,
      //   children: [
      //     // {
      //     //   path: 'new',
      //     //   element: <NewSeasonSubdivision />,
      //     // },
      //   ],
      // },
    ],
  },
  // {
  //   path: 'invoices',
  //   element: <Invoices />,
  //   children: [
  //     {
  //       path: 'schools',
  //       element: <InvoiceOrganizations />,
  //       children: [
  //         {
  //           path: 'new',
  //           element: <NewInvoiceOrganization />,
  //         },
  //         {
  //           path: 'edit/:id',
  //           element: <EditSchoolInvoice />,
  //         },
  //       ],
  //     },
  //     {
  //       path: 'students',
  //       element: <InvoiceStudents />,
  //       children: [
  //         {
  //           path: 'new',
  //           element: <NewInvoiceStudent />,
  //         },
  //         {
  //           path: 'edit/:id',
  //           element: <EditStudentInvoice />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    path: 'classrooms',
    element: <AllClassrooms />,
    children: [
      {
        path: 'schedule-ogma/:id',
        element: <ClassroomsScheduleHours />,
      },
      // {
      //   path: 'book-student/:bookId',
      //   element: <StudentBookHours />,
      // },
      // {
      //   path: 'view/:id',
      //   element: <ViewClassroom />,
      // },
    ],
  },

  {
    path: 'classrooms/view/:id',
    element: <ViewClassroom />,
    children: [
      {
        path: 'l/:lessonid/content/:publicId',
        element: <ViewLesson />,
      },
    ],
  },
  {
    path: 'classrooms/preview/:id',
    element: <PreviewClassroom />,
  },
  {
    path: 'tournaments',
    element: <Tournaments />,
    children: [
      {
        path: 'all',
        element: <AllTournaments />,
        children: [
          // {
          //   path: 'new',
          //   element: <NewTournament />,
          // },
          // {
          //   path: 'edit/:id',
          //   element: <EditTournament />,
          // },
          // {
          //   path: 'archive/:id',
          //   element: <ArchiveTournament />,
          // },
          // {
          //   path: 'duplicate/:id',
          //   element: <DuplicateTournament />,
          // },
        ],
      },
    ],
  },
  {
    path: 'tournaments/view/:id',
    element: <Tournament />,
    children: [
      // {
      //   path: 'edit-tournament',
      //   element: <EditTournament />,
      // },
      {
        path: '',
        element: <TournamentOverview />,
      },
      {
        path: 'bracket',
        element: <Bracket />,
        children: [
          // {
          //   path: 'generate-bracket',
          //   element: <GenerateBracket />,
          // },
          // {
          //   path: 'edit',
          //   element: <EditBracket />,
          // },
          // {
          //   path: 'edit-bracket/:bracketid',
          //   element: <EditSingleBracket />,
          // },
          // {
          //   path: "manual-bracket",
          //   element: <ManualBracket />,
          // }
        ],
      },

      {
        path: 'matches',
        element: <TournamentMatches />,
      },
      // {
      //   path: "add",
      //   element: <NewTeamTournament />
      // },
      {
        path: 'teams',
        element: <TournamentTeams />,
        children: [
          // {
          //   path: 'edit',
          //   element: <NewTeamTournament />,
          // },
        ],
      },
      {
        path: 'standings',
        element: <TournamentStandings />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '*',
    // element: <Dashboard />,
  },
  {
    path: 'Users',
    element: <Users />,
    children: [
      // {
      //   path: 'new',
      //   element: <Modal />,
      // },
      // {
      //   path: 'edit/:id',
      //   element: <EditUser />,
      // },
      {
        path: 'archive/:id',
        element: <ArchiveUser />,
      },
    ],
  },
  {
    path: 'teams/view/:id',
    element: <ViewTeam />,
    children: [
      {
        path: '',
        element: <TeamViewStats />,
      },
      {
        path: 'members',
        element: <TeamMembers />,
        children: [
          {
            path: 'add',
            element: <AddStudent />,
          },
        ],
      },
      // {
      //   path: 'stats',
      //   element: <TeamViewStats />,
      // },
      {
        path: 'team-schedule',
        element: <TeamSchedule />,
      },

      {
        path: 'add-to-season',
        element: <TeamSeasonSelection />,
      },
    ],
  },
  {
    path: 'users/view/:id',
    children: [
      //     {
      //       path: 'ogma',
      //       element: <OgmaProfile />,
      //       children: [
      //         {
      //           path: 'invoices',
      //           element: <OgmaProfileInvoices />,
      //         },
      //         {
      //           path: 'schools',
      //           element: <OgmaProfileSchools />,
      //         },
      //       ],
      //     },
      //     {
      //       path: 'guardian',
      //       element: <ViewGuardian />,
      //       children: [
      //         {
      //           path: 'overview',
      //           element: <GuardianOverview />,
      //         },
      //         {
      //           path: 'students',
      //           element: <GuardianMyStudents />,
      //           children: [
      //             {
      //               path: 'new',
      //               element: <AddMyStudent />,
      //             },
      //             {
      //               path: 'manage/:subscriptionId',
      //               element: <ManageStudentSubscription />,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      {
        path: 'student',
        element: <ViewUser />,
        children: [
          {
            path: 'overview',
            // element: <UserOverview />,
            element: <UserStats />,
          },
          // {
          // path: 'stats',
          // },
        ],
      },
      //     {
      //       path: 'coach',
      //       element: <ViewCoach />,
      //       children: [
      //         {
      //           path: 'overview',
      //           element: <CoachOverview />,
      //         },
      //         {
      //           path: 'classrooms',
      //           element: <Classrooms />,
      //           // children: [
      //           //   {
      //           //     path: 'new',
      //           //     element: <NewClassroom />,
      //           //   },
      //           // ],
      //         },
      //         {
      //           path: 'training',
      //           element: <TrainingContent />,
      //           children: [
      //             {
      //               path: 'new',
      //               element: <NewClassroom />,
      //             },
      //             {
      //               path: 'edit/:cId',
      //               element: <EditClassroom />,
      //             },
      //             {
      //               path: 'archive/:cId',
      //               element: <ArchiveClassroom />,
      //             },
    ],
  },
  //         {
  //           path: 'training/lesson/:cId',
  //           element: <Lessons />,
  //           children: [
  //             {
  //               path: 'new',
  //               element: <NewLesson />,
  //             },
  //             {
  //               path: 'edit/:lId',
  //               element: <EditLesson />,
  //             },
  //             {
  //               path: 'delete/:lId',
  //               element: <DeleteLesson />,
  //             },
  //             {
  //               path: 'content/:lId',
  //               element: <AddLessonContent />,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    path: 'coaches/view/:id',
    element: <ViewCoach />,
    children: [
      // {
      //   path: 'overview',
      //   element: <CoachOverview />,
      // },
      {
        path: 'classrooms',
        element: <Classrooms />,
        children: [
          // {
          //   path: 'new',
          //   element: <NewClassroom />,
          // },
        ],
      },
      {
        path: 'training',
        element: <TrainingContent />,
        children: [
          // {
          //   path: 'new',
          //   element: <NewClassroom />,
          // },
          // {
          //   path: 'edit/:cId',
          //   element: <EditClassroom />,
          // },
          // {
          //   path: 'archive/:cId',
          //   element: <ArchiveClassroom />,
          // },
        ],
      },
      // {
      //   path: 'training/new',
      //   element: <NewClassroom />,
      // },
      // {
      //   path: 'training/edit/:cId',
      //   element: <EditClassroom />,
      // },
      {
        path: 'training/lesson/:cId',
        element: <Lessons />,
        children: [
          // {
          //   path: 'new',
          //   element: <NewLesson />,
          // },
          // {
          //   path: 'edit/:lId',
          //   element: <EditLesson />,
          // },
          // {
          //   path: 'delete/:lId',
          //   element: <DeleteLesson />,
          // },
          // {
          //   path: 'content/:lId',
          //   element: <AddLessonContent />,
          // },
        ],
      },
    ],
  },
  {
    path: 'organization',
    element: <ViewOrganization />,
    children: [
      {
        path: '',
        element: <OrganizationSchools />,
      },
      // {
      //   path: 'schools',
      //   element: <OrganizationSchools />,
      // },
      {
        path: 'teams',
        element: <OrganizationTeams />,
      },
      // {
      //   path: 'coaches',
      //   element: <OrganizationCoaches />,
      //   children: [
      //     {
      //       path: 'add',
      //       element: <OrganizationAddCoaches />,
      //     },
      //   ],
      // },
    ],
  },
  {
    path: 'organizations/view/:id',
    element: <ViewOrganization />,
    children: [
      // {
      //   path: '',
      //   element: <OrganizationOverview />,
      // },
      {
        path: '',
        element: <OrganizationSchools />,
      },
      {
        path: 'teams',
        element: <OrganizationTeams />,
      },
      // {
      //   path: 'coaches',
      //   element: <OrganizationCoaches />,
      //   children: [
      //     {
      //       path: 'add',
      //       element: <OrganizationAddCoaches />,
      //     },
      //   ],
      // },
    ],
  },
  {
    path: 'Teams',
    element: <Teams />,
    children: [
      {
        path: 'new',
        element: <NewTeamModal />,
      },
      // {
      //   path: 'edit/:id',
      //   element: <UpdateTeamModal />,
      // },
      // {
      //   path: 'archive/:id',
      //   element: <ArchiveTeam />,
      // },
      {
        path: 'view/:id',
        element: <ViewTeam />,
      },
    ],
  },
  {
    path: 'schools/view/:id',
    element: <ViewSchool />,
    children: [
      {
        path: '',
        element: <SchoolStudents />,
      },
      {
        path: 'students',
        element: <SchoolStudents />,
      },
      {
        path: 'teams',
        element: <SchoolTeams />,
      },
    ],
  },
  /*
  {
    path: 'coaches',
    element: <ViewCoaches />,
    children: [
      // {
      //   path: 'new',
      //   element: <NewCoachModal />,
      // },
      // {
      //   path: 'edit/:id',
      //   element: <UpdateCoachModal />,
      // },
      // {
      //   path: 'archive/:id',
      //   element: <ArchiveCoachModal />,
      // },
    ],
    // children: [
    //   {
    //     path: "overview",
    //     element: <SchoolOverview />,
    //   },
    //   {
    //     path: "students",
    //     element: <SchoolStudents />,
    //   },
    //   {
    //     path: "teams",
    //     element: <SchoolTeams />,
    //   },
    // ],
  }, 
  */
  // {
  //   path: 'sponsors',
  //   element: <Sponsors />,
  //   children: [
  //     // {
  //     //   path: 'new',
  //     //   element: <NewSponsor />,
  //     // },
  //     // {
  //     //   path: 'edit/:id',
  //     //   element: <EditSponsor />,
  //     // },
  //     // {
  //     //   path: 'archive/:id',
  //     //   element: <ArchiveSponsor />,
  //     // },
  //   ],
  // },
  {
    path: '/schools',
    element: <Schools />,
    children: [
      // {
      //   path: 'new',
      //   element: <NewSchoolModal />,
      // },
      // {
      //   path: 'edit/:id',
      //   element: <UpdateSchoolModal />,
      // },
      // {
      //   path: 'archive/:id',
      //   element: <ArchiveSchool />,
      // },
    ],
  },
  // {
  //   path: '/organizations',
  //   element: <Organizations />,
  //   children: [
  //     {
  //       path: 'new',
  //       element: <NewOrganizationModal />,
  //     },
  //     {
  //       path: 'edit/:id',
  //       element: <UpdateOrganizationModal />,
  //     },
  //     {
  //       path: 'archive/:id',
  //       element: <ArchiveOrg />,
  //     },
  //   ],
  // },
  {
    path: '/schedule',
    element: <AllSchedule />,
  },

  {
    path: '/games',
    element: <AllGames />,
    children: [
      {
        path: '',
        element: <GameOverview />,
        children: [
          {
            path: 'add/:id',
            element: <AddGameAccount />,
          },
          {
            path: 'delete/:id',
            element: <DeleteGameAccount />,
          },
          //   children: [
          //     {
          //       path: 'manual',
          //       element: <GameManual />,
          //     },
          //     {
          //       path: 'api',
          //       element: <GameAPI />,
          //     },
          //   ],
          // },
        ],
      },
      {
        path: 'teams',
        element: <GameAllTeams />,
        children: [
          // {
          //   path: 'new',
          //   element: <NewTeamModal />,
          // },
        ],
      },
    ],
  },

  // {
  //   path: '/support',
  //   element: <Support />,
  //   children: [
  //     {
  //       path: 'create-new-ticket',
  //       element: <CreateNewTicket />,
  //       children: [
  //         {
  //           path: 'create-new-ticket',
  //           element: <CreateNewTicket />,
  //         },
  //       ],
  //     },
  //   ]
  // },

  {
    path: '/support',
    element: <Support />,
    children: [
      {
        path: '',
        element: <SupportOtherRolesView />,
      },
      {
        path: 'viewMytickets',
        element: <ViewMyTickets />,
      },
      {
        path: 'create-new-ticket',
        element: <CreateNewTicket />,
      },
      {
        path: 'viewMyticket/:id',
        element: <ViewTicketUser />,
        children: [
          {
            path: 'approve',
            element: <ApproveTicketUser />,
          },
          {
            path: 'not-approve',
            element: <UserNotApproveTicket />,
          },
        ],
      },
    ],
  },

  {
    path: '/Settings',
    element: <Settings />,

    children: [
      {
        path: '',
        element: <MyAccount />,
      },
      {
        path: 'success',
        element: <SuccessSettings />,
      },

      {
        path: 'security/',
        element: <SecuritySettings />,
      },
      {
        path: 'notification/',
        element: <NotificationSettings />,
      },
      {
        path: 'usernames/',
        element: <Usernames />,
      },
      {
        path: 'delete/:id',
        element: <DeleteUser />,
      },
    ],
  },

  {
    path: 'invitedusers',
    element: <OgmaInvitedUsers />,
    children: [
      {
        path: 'cancel/:id',
        element: <OgmaInvitedUsersCancel />,
      },
    ],
  },

  // {
  //   path: 'students',
  //   element: <OgmaViewStudents />,
  //   children: [
  //     {
  //       path: 'archive/:id/:name',
  //       element: <ArchiveStudents />
  //     }
  //   ]
  // },
];
