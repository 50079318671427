import { Link, useParams } from 'react-router-dom';
import { AvatarImage } from '../../../components/avatar-image';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { PiImage } from 'react-icons/pi';
import { useEffect, useState } from 'react';
import CanIView from '../../../components/can-i-view/can-i-view';

interface ICoopTeamOrgs {
  id: number;
  name: string;
}

interface IOgmas {
  id: number;
  in_game_name: string;
  discord_name: string;
  username: string;
  InGameName: Array<any>;
  ogmas: Array<{ key: string }>;
}

const toggleState = { viewMoreOrg: false, viewMoreOgma: false };
type IToggleState = typeof toggleState;

const ViewSchoolOrgMatch = ({
  teamId,
  teamName,
  teamGameName,
  schoolId,
  schoolName,
  orgId,
  orgName,
  image,
  coopTeamOrgs,
  coopOgmas,
  schoolOgmas,
  gameId,
  extraStyle,
  canViewBenchStatus,
  isBenched,
  setShowHover,
  showHover,
}: {
  teamId: number;
  teamName: string;
  teamGameName: string;
  schoolId: number;
  schoolName: string;
  orgId: number;
  orgName: string;
  image?: string;
  coopTeamOrgs?: Array<any>;
  coopOgmas?: Array<any>;
  schoolOgmas?: Array<IOgmas> | any;
  extraStyle?: string;
  gameId?: number | any;
  canViewBenchStatus?: boolean;
  isBenched?: boolean;
  setShowHover: Function;
  showHover?: boolean;
}) => {
  const param = useParams();

  const [showAll, setShowAll] = useState<IToggleState>({
    viewMoreOrg: false,
    viewMoreOgma: false,
  });

  const toggleShowAll = (field: string) => {
    switch (field) {
      case 'ogma':
        return setShowAll((prevState: IToggleState) => ({
          ...prevState,
          viewMoreOgma: true,
        }));
      case 'org':
        return setShowAll((prevState: IToggleState) => ({
          ...prevState,
          viewMoreOrg: true,
        }));
      default:
        return setShowAll({
          viewMoreOrg: false,
          viewMoreOgma: false,
        });
    }
  };

  const [orgOgmas, setOrgOgmas] = useState<IOgmas | any>(undefined);

  useEffect(() => {
    if (coopOgmas?.length) {
      const data = coopOgmas?.flatMap((o: any) => o?.ogmas)?.filter((o: any) => o);
      setOrgOgmas(data);
    }
  }, []);

  return (
    <div
      className='flex flex-col gap-5 p-4 z-50'
      onClick={(event) => event.stopPropagation()}
      onMouseLeave={() => setShowHover(false)}
    >
      <div className='flex gap-2 items-center pb-5 border-b-1'>
        {image ? (
          <AvatarImage source={image} extraClass='!w-[60px] !h-[60px]' />
        ) : (
          <div className='bg-white flex items-center justify-center w-[60px] h-[60px] rounded-full'>
            <PiImage size={25} />
          </div>
        )}
        <div className='flex flex-1 min-w-[1px] flex-col gap-1 w-full break-words'>
          {canViewBenchStatus ? (
            <div>
              <span className='text-sm font-bold text-[#D38AFF]'>BENCHED</span>
            </div>
          ) : null}
          {teamName?.length ? (
            <div className='flex items-center gap-1 break-words'>
              <h5 className='text-base text-white'>Team:</h5>
              <Link
                to={`/teams/view/${teamId}`}
                className='flex items-center gap-2 break-words w-full'
              >
                <span className='text-white text-base border-b-1 break-words w-full'>
                  {teamName}
                </span>
                <MdOutlineArrowForwardIos size={12} color='white' />
              </Link>
            </div>
          ) : null}
          {teamGameName?.length ? (
            <div className='flex items-center gap-1'>
              <h5 className='text-base text-white'>Game:</h5>
              <span className='text-white text-base'>{teamGameName}</span>
            </div>
          ) : null}
        </div>
      </div>

      <div
        className={`flex flex-col gap-2 pb-5 ${
          schoolId || orgId || coopTeamOrgs?.length ? 'border-b-1' : ''
        }`}
      >
        {coopTeamOrgs?.length ? (
          <div className='flex flex-col gap-2'>
            {coopTeamOrgs
              ?.slice(0, showAll?.viewMoreOrg ? undefined : 2)
              ?.map((orgs: ICoopTeamOrgs) =>
                orgs?.id ? (
                  <div className='flex items-center gap-1' key={orgs?.id}>
                    <h5 className='text-base text-white'>Organization:</h5>
                    <Link
                      to={`/organizations/view/${orgs?.id}`}
                      className='flex items-center gap-2'
                    >
                      <span className='text-white text-base border-b-1'>{orgs?.name}</span>
                      <MdOutlineArrowForwardIos size={12} color='white' />
                    </Link>
                  </div>
                ) : null,
              )}
            {!showAll?.viewMoreOrg && coopTeamOrgs?.length > 2 ? (
              <>
                <button
                  className='text-[12px] text-white border-b-1 w-fit'
                  onClick={() => toggleShowAll('org')}
                >
                  View More
                </button>
              </>
            ) : null}
          </div>
        ) : (
          <>
            {schoolId ? (
              <div className='flex items-center gap-1'>
                <h5 className='text-base text-white'>School:</h5>
                <Link to={`/schools/view/${schoolId}`} className='flex items-center gap-2'>
                  <span className='text-white text-base border-b-1'>{schoolName}</span>
                  <MdOutlineArrowForwardIos size={12} color='white' />
                </Link>
              </div>
            ) : null}
            {orgId ? (
              <div className='flex items-center gap-1'>
                <h5 className='text-base text-white'>Organization:</h5>
                <Link to={`/organizations/view/${orgId}`} className='flex items-center gap-2'>
                  <span className='text-white text-base border-b-1'>{orgName}</span>
                  <MdOutlineArrowForwardIos size={12} color='white' />
                </Link>
              </div>
            ) : null}
          </>
        )}
      </div>
      <div className='flex flex-col gap-2'>
        {orgOgmas?.length
          ? orgOgmas?.slice(0, showAll?.viewMoreOgma ? undefined : 2)?.map(
              (ogma: IOgmas, i: number) =>
                (ogma?.username ||
                  ogma?.InGameName?.some((d: IOgmas) => d?.in_game_name) ||
                  ogma?.InGameName?.some((d: IOgmas) => d?.discord_name)) && (
                  <div className='flex flex-col' key={i}>
                    {ogma?.username && (
                      <h5 className='text-base text-white'>OGMA: {ogma?.username}</h5>
                    )}
                    {ogma?.InGameName?.filter((g: any) => g?.game_id === gameId)?.map(
                      (d: IOgmas, j: number) => (
                        <div className='flex flex-col' key={j}>
                          {d?.in_game_name && (
                            <h5 className='text-base text-white'>
                              In-Game Name: {d?.in_game_name}
                            </h5>
                          )}
                          {d?.discord_name && (
                            <h5 className='text-base text-white'>Discord: {d?.discord_name}</h5>
                          )}
                        </div>
                      ),
                    )}
                  </div>
                ),
            )
          : schoolOgmas?.ogmas?.length
          ? schoolOgmas?.ogmas
              ?.slice(0, showAll?.viewMoreOgma ? undefined : 2)
              .map((ogma: IOgmas, i: number) => (
                <div className='flex flex-col' key={i}>
                  {ogma?.username && (
                    <h5 className='text-base text-white'>OGMA: {ogma?.username}</h5>
                  )}
                  {ogma?.InGameName?.filter((g: any) => g?.game_id === gameId)?.map(
                    (d: IOgmas, j: number) => (
                      <div className='flex flex-col' key={j}>
                        {d?.in_game_name && (
                          <h5 className='text-base text-white'>In-Game Name: {d?.in_game_name}</h5>
                        )}
                        {d?.discord_name && (
                          <h5 className='text-base text-white'>Discord: {d?.discord_name}</h5>
                        )}
                      </div>
                    ),
                  )}
                </div>
              ))
          : null}
      </div>
      {(!showAll?.viewMoreOgma && orgOgmas?.map((org: IOgmas) => org?.id)?.length > 2) ||
      (!showAll?.viewMoreOgma && schoolOgmas?.ogmas?.map((ogm: IOgmas) => ogm?.id)?.length > 2) ? (
        <button
          className='text-[12px] text-white border-b-1 w-fit'
          onClick={() => toggleShowAll('ogma')}
        >
          View More
        </button>
      ) : null}
      {orgOgmas?.length || schoolOgmas?.ogmas?.length ? <hr /> : null}
      <CanIView component='team.bench.button'>
        {isBenched ? (
          <Link
            to={`./unbench/${teamId}`}
            className='w-full text-md py-2 bg-white text-[#A435E8] font-bold text-center rounded-lg hover:text-purple-700'
            onClick={() => setShowHover(!showHover)}
          >
            UNBENCH
          </Link>
        ) : (
          <Link
            to={`./bench/${teamId}`}
            className='w-full text-md py-2 bg-white text-[#A435E8] font-bold text-center rounded-lg hover:text-purple-700'
            onClick={() => setShowHover(!showHover)}
          >
            BENCH
          </Link>
        )}
      </CanIView>
    </div>
  );
};

export default ViewSchoolOrgMatch;
