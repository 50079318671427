import React from 'react';
import { useSelector } from 'react-redux';
import { selectMatchData } from '../../../slices/matchSlice';
import { firstBy } from 'thenby';
import { MatchMemberRowAway } from '../components/match-member-row-away';
import { useGetAllUsersByIdsQuery } from '../../../slices/userApiSlice';

interface IInGameName {
  discord_name: string;
  in_game_name: string;
}

export function CurrentTeamMatchAway({ editMode }: any) {
  const matchData = useSelector(selectMatchData);
  const { data, isLoading } = useGetAllUsersByIdsQuery(matchData.away_team?.[0]?.id, {
    skip: !matchData?.away_team?.[0],
  });

  const users = data?.filter((u: any) => matchData.away_team_active.includes(u?.id)) ?? [];
  const gameLimit = matchData.Game.active_limit;
  const userLength = matchData.away_team_active.length;
  const arr = [
    ...users.map((u: any) => ({
      ...u,
      InGameName: u?.InGameName?.filter(
        (name: any) => name.game_id === matchData.away_team?.[0]?.game_id,
      ),
    })),
    ...Array.from(new Array(gameLimit - userLength > 0 ? gameLimit - userLength : 0)),
  ];

  if (isLoading) return <></>;
  return (
    <div className={`flex flex-col items-center gap-2 rounded-t-xl `}>
      <div className='flex w-fit max-w-full flex-col justify-evenly  flex-wrap gap-2 '>
        {/* {matchData.away_team?.[0]?.User &&
          JSON.parse(JSON.stringify(matchData.away_team?.[0]?.User)) */}
        {arr
          // @ts-ignore
          .sort(
            firstBy(
              (a: any, b: any) =>
                // @ts-ignore
                (b?.id === matchData.away_team?.[0]?.captain) -
                // @ts-ignore
                (a?.id === matchData.away_team?.[0]?.captain),
            ).thenBy((a: any, b: any) => {
              const aIndex = matchData.away_team_active.findIndex((ht: any) => ht === a?.id);
              const bIndex = matchData.away_team_active.findIndex((ht: any) => ht === b?.id);
              return bIndex - aIndex;
            }),
          )
          .map((u: any, i: any) => (
            <React.Fragment key={i}>
              {/* {matchData.away_team_active.includes(u.id) ? ( */}
              <MatchMemberRowAway
                editMode={editMode}
                gameId={matchData.away_team?.[0]?.game_id}
                {...(u ?? {})}
                InGameName={
                  u?.InGameName.filter(
                    (name: any) => name.game_id === matchData.away_team?.[0]?.game_id,
                  ) ?? ''
                }
                activeMember
                key={i}
                id={u?.id ?? i}
                isCaptain={Number(u?.id) === matchData.away_team?.[0]?.captain ?? false}
                isCheckedIn={matchData.checked_in_students.includes(u?.id)}
                studentId={u?.id ?? null}
                gameName={matchData?.Game?.name}
                // discord={u?.InGameName?.map((u: IInGameName) => u?.discord_name)}
                // inGameName={u?.InGameName?.map((u: IInGameName) => u?.in_game_name)}
              />
              {/* ) : null} */}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
}
