import { useEffect, useState } from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { AiFillStar } from 'react-icons/ai';
import { FaTrashAlt } from 'react-icons/fa';
import {
  useRemoveUserFromTeamMutation,
  useSetCaptainOfTheTeamMutation,
} from '../../../../../../slices/userApiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, AvatarFallback, AvatarImage } from '../../../../../../components/ui/avatar/Avatar';
import { firstLetterToUpperCase } from '../../../../../../utils/utils';
export function UserInfo({
  id,
  editMode,
  username,
  type,
  isArchived,
  image,
  ...u
}: {
  id: number;
  editMode: boolean;
  username: string;
  type: string;
  isArchived: boolean;
  image?: string;
  u: { [key: string]: any };
}) {
  const navigate = useNavigate();
  const [drag, setDrag] = useState(true);

  const { isOver, setNodeRef: setDropNode } = useDroppable({ id: id });
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id,
    disabled: !editMode || !drag,
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  const param = useParams();
  const handleRemoveUser = (e: any) => {
    e.stopPropagation();
    removeUserFromTeam({ id: param.id, body: { studentId: id, team: type } });
  };

  const [
    removeUserFromTeam,
    { data: rtData, isLoading: rtIsLoading, isError: rtIsError, error: rtError },
  ] = useRemoveUserFromTeamMutation();
  const [setCaptainOfTheTeam, { data: cData, isLoading: cIsLoading, isError: cIsError }] =
    useSetCaptainOfTheTeamMutation();
  const handleSetCaptain = (e: any) => {
    e.stopPropagation();
    setCaptainOfTheTeam({ id: param.id, studentId: id });
  };
  console.log('editMode', editMode);
  return (
    <div
      className={`relative flex flex-col items-center rounded-xl p-2 ${
        editMode ? `border-dashed border-2 bg-white  ` : ''
      } ${isOver ? '!bg-green-500' : ''} ${isDragging ? 'z-50' : ''}`}
      style={style}
      onClick={() => navigate(`/users/view/${id}/Student/overview`)}
      ref={(el) => {
        setNodeRef(el);
        setDropNode(el);
      }}
      {...listeners}
      {...attributes}
    >
      {editMode ? (
        <>
          <div
            onMouseLeave={() => setDrag(true)}
            onMouseEnter={() => setDrag(false)}
            onClick={handleRemoveUser}
            className='absolute  top-1  right-1 m-auto w-fit h-fit '
          >
            <FaTrashAlt size={20} className='text-gray-500 hover:text-black' />
          </div>

          {isArchived ? (
            <></>
          ) : (
            <div className='absolute  top-1 left-0 right-0 m-auto w-fit h-fit z-[1]'>
              {/* @ts-ignore */}
              {id === u.captain ? (
                <AiFillStar
                  onMouseLeave={() => setDrag(true)}
                  onMouseEnter={() => setDrag(false)}
                  onClick={(e: any) => {
                    e.stopPropagation();
                  }}
                  color='gold'
                  stroke='true'
                  size={30}
                />
              ) : (
                <AiFillStar
                  onMouseEnter={() => setDrag(false)}
                  onMouseLeave={() => setDrag(true)}
                  stroke='black'
                  strokeWidth={20}
                  color='white'
                  onClick={handleSetCaptain}
                  className='hover:scale-125'
                  size={30}
                />
              )}
            </div>
          )}
        </>
      ) : (
        <div className='absolute top-1 left-0 right-0 m-auto w-fit h-fit z-10'>
          {/* @ts-ignore */}
          {id === u.captain ? <AiFillStar color='gold' size={30} /> : null}
        </div>
      )}

      <div
        className={`w-[125px] h-[125px] rounded-full bg-gray-200 ${
          isArchived ? 'bg-red-400' : ''
        } relative overflow-hidden`}
      >
        <Avatar
          className={`absolute top-0 left-0 right-0 bottom-0 w-full h-full ${
            isArchived ? 'bg-red-400' : ''
          }`}
        >
          <AvatarImage src={image} />
          <AvatarFallback className={`${isArchived ? 'bg-red-400' : ''}`}>
            {firstLetterToUpperCase(username) ?? 'AS'}
          </AvatarFallback>
        </Avatar>
        {/* <img src={image} className='absolute top-0 left-0 right-0 bottom-0' /> */}
      </div>
      {/* <AvatarImage source={``} size={'lg'} /> */}
      {isArchived ? 'Archived Student' : username}
      {/* <p className='text-xs font-extralight text-gray-400'>Signed up 10 minutes ago</p> */}
    </div>
  );
}
