import loginbg from '../../../assets/main_bg.jpg';
import { Link, Outlet, useLocation } from 'react-router-dom';

import LoginForm from './LoginForm';
import { useDispatch, useSelector } from 'react-redux';
import { changeLogOut, selectLogout, selectToken } from '../../../slices/auth';
import { useEffect } from 'react';
import { AppDispatch } from '../../../store';
import { ToastContainer, toast } from 'react-toastify';

const Login = () => {
  const routeLocation = useLocation();
  console.log('route location', routeLocation);
  const token = useSelector(selectToken);
  const logOut = useSelector(selectLogout);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (logOut === false) {
      toast.info('You have successfully logged out', {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(changeLogOut({ logoutStatus: false }));
    }
  }, [logOut]);

  return (
    <>
      <ToastContainer />
      <div className='flex flex-col sm:flex-row justify-center h-screen w-full'>
        <div className='hidden sm:block w-1/2'>
          <img className='w-full h-full object-cover' src={loginbg} alt='' />
        </div>
        <Outlet />
        <div className='w-full sm:w-1/2 bg-white-900 flex flex-col justify-center gap-10'>
          <div></div>
          <LoginForm />
          {/* <div className='text-center'>
            <Link to='support' className='border-b-1 border-[#6B7280] text-[#6B7280] font-bold'>
              Contact support
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Login;
