import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AvatarImage } from '../../../components/avatar-image';
import useIntersectionObserver from '../../../hooks/use-intersection-observer';
import { selectUser } from '../../../slices/auth';
import { useInitChatMessagesQuery } from '../../../slices/matchApiSlice';
import { selectChatMessages, selectMatchData } from '../../../slices/matchSlice';
import { UserRoles } from '../../../utils/constants';
import DeleteChatMessageMatchAll from '../../../components/delete-chat-msg-all-match/DeleteChatMessageMatchAll';
import { AiOutlineStop } from 'react-icons/ai';
import { FaArrowDown } from 'react-icons/fa';

export function AllChatMessages() {
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [fetch, setFetch] = useState(false);
  const elementRef = useRef(null);
  const allChatMessages = useSelector(selectChatMessages);
  const user = useSelector(selectUser);
  const matchData = useSelector(selectMatchData);
  const [chatMessageId, setChatMsgId] = useState<any>(null);
  const [isAtBottom, setIsAtBottom] = useState(true);

  const param = useParams();
  const [page, setPage] = useState<number>();
  const { data, isLoading, isSuccess } = useInitChatMessagesQuery({
    id: param.id,
    page,
  });
  const entry = useIntersectionObserver(elementRef, {});
  const isVisible = !!entry?.isIntersecting;

  const hasIOSupport = !!window.IntersectionObserver;
  useEffect(() => {
    if (isSuccess) console.log('isVisible', fetch);
    if (!isVisible || !fetch || isLoading || !data || !data.meta.next) return;
    setPage(data.meta.next);
  }, [isVisible, data?.meta, fetch]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    console.log('isLoading', isLoading, fetch);
    if (isLoading) setFetch(false);
    else setTimeout(() => setFetch(true), 2000);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isLoading]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
        setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 10);
      }
    };

    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  useEffect(() => {
    if (isAtBottom && chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [allChatMessages, isAtBottom]);

  let x: number;
  {
    user?.team?.map((t: any, i: number) => {
      if (t.game_id === matchData.game_id) {
        x = i;
      }
    });
  }

  const handleMessageClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, msgId: number) => {
    e.stopPropagation();
    if (chatMessageId === msgId) {
      setChatMsgId(null);
    } else {
      setChatMsgId(msgId);
    }
  };

  return (
    <div
      className='flex-1 p-2 gap-2 flex flex-col overflow-auto'
      onClick={() => setChatMsgId(null)}
      ref={chatContainerRef}
    >
      {allChatMessages.map((ch: any, i: number) => (
        <React.Fragment key={i}>
          {user.roles === UserRoles.Admin || user.roles === UserRoles.Ogma ? (
            <div
              className={`flex items-center gap-2 w-full max-w-full relative ${
                ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? 'justify-end' : ''
              }`}
              key={i}
            >
              <div
                className={`flex gap-2 ${
                  ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id
                    ? 'order-2'
                    : 'order1'
                }`}
              >
                <AvatarImage
                  size='xl'
                  source={ch.image ? ch.image : ch.user?.image}
                  extraClass={
                    ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? 'order-2' : ''
                  }
                />
                {user.roles === UserRoles.Admin
                  ? chatMessageId === ch?.id &&
                    user.roles === UserRoles.Admin && <DeleteChatMessageMatchAll msgId={ch?.id} />
                  : user.roles === UserRoles.Ogma
                  ? chatMessageId === ch?.id &&
                    user.roles === UserRoles.Ogma && <DeleteChatMessageMatchAll msgId={ch?.id} />
                  : null}
                <div
                  className={`chat__body relative min-w-[7rem] ${
                    ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id
                      ? 'order-1 before:hidden cursor-pointer'
                      : 'after:hidden cursor-pointer'
                  } ${ch.isDeleted ? 'opacity-75' : ''} `}
                  onClick={(e) => handleMessageClick(e, ch?.id)}
                >
                  <div
                    className={`flex gap-2 ${
                      ch.isMe || ch?.user?.id === user.id || ch?.user_id === user.id
                        ? 'justify-end'
                        : ''
                    }`}
                  >
                    <p className={`text-black font-bold `}>
                      {ch.username ? ch.username : ch.user?.username}
                    </p>
                    {/* <span>{ch.team ? ch.team : ch.user?.team[x]?.teamName}</span> */}
                  </div>
                  <p className='text-black break-all'>{ch.message}</p>
                  <div className='flex justify-end text-xs'>
                    <p>{ch.time ? new Date(ch.time).toTimeString().split(' ')[0] : ch.clock}</p>
                  </div>
                  {ch.isDeleted ? (
                    <div
                      className={`absolute z-50 bottom-1 ${
                        ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? '' : ''
                      }`}
                    >
                      <div className='group'>
                        <AiOutlineStop className='text-red-500' />
                        <div
                          className={`hidden group-hover:block absolute top-[-50px] z-50 w-40 max-w-40 h-fit bg-black opacity-75 rounded p-1 ${
                            ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id
                              ? 'left-0'
                              : 'left-0'
                          }`}
                        >
                          <span className='overflow-ellipsis whitespace-normal text-white'>
                            This message has been deleted
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/* {ch.isDeleted ? (
                <div className={`${ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? 'order-1' : 'order-2'}`}>
                  <FaExclamation className='text-red-500' />
                </div>
              ) : (
                null
              )} */}
            </div>
          ) : user.roles !== UserRoles.Admin &&
            user.roles !== UserRoles.Ogma &&
            ch.isDeleted !== 1 ? (
            <div
              className={`flex gap-2 w-full max-w-full relative ${
                ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? 'justify-end' : ''
              }`}
              key={i}
            >
              <AvatarImage
                size='xl'
                source={ch.image ? ch.image : ch.user?.image}
                extraClass={
                  ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? 'order-2' : ''
                }
              />
              {/* {chatMessageId === ch?.id && user.roles === UserRoles.Admin && <DeleteChatMessageMatchAll msgId={ch?.id} />} */}
              <div
                className={`chat__body ${
                  ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id
                    ? 'order-1 before:hidden cursor-pointer'
                    : 'after:hidden cursor-pointer'
                } `}
                onClick={(e) => handleMessageClick(e, ch?.id)}
              >
                <div
                  className={`flex gap-2 ${
                    ch.isMe || ch?.user?.id === user.id || ch?.user_id === user.id
                      ? 'justify-end'
                      : ''
                  }`}
                >
                  <p className={`text-black font-bold `}>
                    {ch.username ? ch.username : ch.user?.username}
                  </p>
                  {/* <span>{ch.team ? ch.team : ch.user?.team[x]?.teamName}</span> */}
                </div>
                <p className='text-black break-all'>{ch.message} </p>
                <div className='flex justify-end text-xs'>
                  <p>{ch.time ? new Date(ch.time).toTimeString().split(' ')[0] : ch.clock}</p>
                </div>
              </div>
            </div>
          ) : null}
        </React.Fragment>
      ))}
      {!isAtBottom && (
        <div className='border-2 border-black w-fit p-1 text-sm flex self-center items-center rounded absolute bottom-[210px] left-1/2 transform -translate-x-1/2'>
          More Messages
          <FaArrowDown className='ml-2' />
        </div>
      )}
      {/* {allChatMessages.map((ch: any, i: number) => (
        <div
          className={`flex gap-2 w-full max-w-full relative ${ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? 'justify-end' : ''
            }`}
          key={i}
        >
          <AvatarImage
            size='xl'
            source={ch.image ? ch.image : ch.user?.image}
            extraClass={
              ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? 'order-2' : ''
            }
          />
          {chatMsgId === ch?.id && user.roles === UserRoles.Admin && <DeleteChatMessageMatchAll msgId={ch?.id} />}
          <div
            className={`chat__body ${ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id
              ? 'order-1 before:hidden cursor-pointer'
              : 'after:hidden cursor-pointer'
              } `}
            onClick={(e) => handleMessageClick(e, ch?.id)}
          >
            <div
              className={`flex gap-2 ${ch.isMe || ch?.user?.id === user.id || ch?.user_id === user.id ? 'justify-end' : ''
                }`}
            >
              <p className={`text-black font-bold `}>
                {ch.username ? ch.username : ch.user?.username}
              </p>
              <span>{ch.team ? ch.team : ch.user?.team[x]?.teamName}</span>
            </div>
            <p className='text-black break-all'>{ch.message} </p>
            <div className='flex justify-end'>
              <p>{ch.time ? new Date(ch.time).toTimeString().split(' ')[0] : ch.clock}</p>
            </div>
          </div>
        </div>
      ))} */}
      {isLoading ? (
        <div>Loading</div>
      ) : (
        <div ref={elementRef} className='flex flex-1 w-full min-h-[10px]'></div>
      )}
      {!hasIOSupport && data.meta.next && <span>Load more</span>}
    </div>
  );
}
