import { Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';
import { Socket, io } from 'socket.io-client';
import { SocketActions, SocketMessages } from '../utils/constants';
import {
  addChatMessage,
  addTeamChatMessage,
  checkIn,
  checkOut,
  pushForfeitHistory,
  removeChatMessageMatchAllById,
  removeTeamChatMessageById,
  setMatchGames,
  setReschedules,
  updateDisputes,
  updateMatchData,
  updateMatchGame,
  updateSocket,
} from '../slices/matchSlice';
import { apiSlice } from '../api/apiSlice';
import { toast } from 'react-toastify';

const MatchSocketMiddleware = (api: MiddlewareAPI) => {
  let socket: Socket | undefined;
  const url = import.meta.env.VITE_SOCKET_URL;
  let classroom_socket: Socket | undefined;
  type ActionType<T> = { payload: T; type: string };
  return (next: Dispatch) => (action: any) => {
    switch (action.type) {
      case SocketActions.Connect:
        api.dispatch(updateSocket('loading'));
        if (socket) {
          api.dispatch(updateSocket('connected'));
          break;
        }
        socket = io(url ?? '', { extraHeaders: { token: '' } });
        let { payload } = action as ActionType<{
          id: string;
          teamId: number | undefined | 'admin';
          team?: [string, number][];
          rooms: number[];
        }>;
        console.log('connecting');
        socket.on(SocketMessages.Connect, () => {
          api.dispatch(updateSocket('connected'));
          socket?.emit('join-room', payload?.id);
          if (!payload?.rooms?.length) {
            payload?.team?.forEach((t: [string, number]) =>
              socket?.emit('join-room', `m${payload?.id}-t${t[1]}`),
            );
          }
          payload.rooms.forEach((r) => socket?.emit('join-room', `m${payload?.id}-t${r}`));
          console.log('connected');
        });
        socket.on(SocketMessages.Match, (msg: any) => {
          api.dispatch(apiSlice.util.invalidateTags(['RescheduleHistory']));
          api.dispatch(updateMatchData(msg));
          if (msg?.forfeitHistory) api.dispatch(pushForfeitHistory(msg?.forfeitHistory));
        });

        socket.on(SocketMessages.Reschedules, (msg: any) => {
          api.dispatch(apiSlice.util.invalidateTags(['RescheduleHistory']));
          api.dispatch(setReschedules(msg));
        });
        socket.on(SocketMessages.TeamChat, (msg: string) => {
          const parsed = JSON.parse(msg);
          api.dispatch(
            addTeamChatMessage({
              user_id: parsed.user_id,
              username: parsed.username,
              team: parsed.team,
              message: parsed.message,
              image: parsed.image,
              clock: parsed.time,
              id: parsed.id,
              isDeleted: parsed.isDeleted,
              room: parsed.room,
            }),
          );
        });
        socket.on(SocketMessages.Dispute, (msg) => {
          api.dispatch(updateDisputes(msg));
          api.dispatch(apiSlice.util.invalidateTags(['MatchChatDispute', 'User']));
        });
        socket.on(SocketMessages.Chat, (msg: string) => {
          const parsed = JSON.parse(msg);
          console.log('got it', msg);
          const user = api.getState().auth.user;
          if (parsed.error && parsed.user_id === user.id) {
            toast.error(parsed.error);
          }
          api.dispatch(
            addChatMessage({
              user_id: parsed.user_id,
              username: parsed.username,
              team: parsed.team,
              message: parsed.message,
              image: parsed.image,
              clock: parsed.time,
              id: parsed.id,
              isDeleted: parsed.isDeleted,
            }),
          );
        });

        socket.on(SocketMessages.DeleteChatMsgMatchAll, (msg: any) => {
          const parsed = JSON.parse(msg);

          if (!parsed.badResponse) {
            api.dispatch(
              removeChatMessageMatchAllById({ id: parsed.id, isDeleted: parsed.isDeleted }),
            );
          }
        });

        socket.on(SocketMessages.DeleteTeamMsg, (msg: any) => {
          const parsed = JSON.parse(msg);
          console.log('update for delete', parsed);

          if (!parsed.badResponse) {
            api.dispatch(removeTeamChatMessageById({ id: parsed.id, isDeleted: parsed.isDeleted }));
          }
        });

        socket.on(SocketMessages.DeleteTeamMsg, (msg: any) => {
          const parsed = JSON.parse(msg);
          console.log('update for delete', parsed);
          api.dispatch(removeTeamChatMessageById({ id: parsed.id, isDeleted: parsed.isDeleted }));
        });

        socket.on(SocketMessages.CheckOut, (msg: any) => {
          api.dispatch(checkOut(msg));
        });
        socket.on(SocketMessages.CheckIn, (msg: any) => {
          api.dispatch(checkIn(msg));
        });
        // socket.on(SocketMessages.CheckOut, (msg: any) => api.dispatch(checkOut(msg)));
        socket.on(SocketMessages.MatchGame, (msg: any) =>
          api.dispatch(setMatchGames(JSON.parse(msg))),
        );
        socket.on(SocketMessages.UpdateMatchGame, (msg: any) =>
          api.dispatch(updateMatchGame(JSON.parse(msg))),
        );
        socket.on(SocketMessages.Disconnect, () => console.log('socket disconnecting'));
        break;
      case SocketActions.Disconnect:
        const { payload: payloadDisconnect } = <ActionType<{ id: number }>>action;
        if (!socket) break;
        socket.close();
        socket.off();
        socket = undefined;
        api.dispatch(updateSocket('disconnected'));
        // api.dispatch(checkOut(payloadDisconnect.id));
        break;
      case SocketActions.DeleteClassroomMessage:
        console.log('testing1');
        if (!socket) break;
        const { payload: deleteChatPayload } = <ActionType<any>>action;
        console.log('testing');
        socket?.emit(SocketMessages.EmitDeleteChatMsg, JSON.stringify(deleteChatPayload));
        break;
      case SocketActions.DeleteMatchMessage:
        if (!socket) break;
        const { payload: deleteMatchChatPayload } = <ActionType<any>>action;

        console.log('delete');
        socket?.emit(SocketMessages.DeleteChatMsgMatchAll, JSON.stringify(deleteMatchChatPayload));
        break;
      case SocketActions.DeleteTeamMessage:
        if (!socket) break;
        const { payload: deleteTeamMatchChatPayload } = <ActionType<any>>action;

        socket?.emit(SocketMessages.DeleteTeamMsg, JSON.stringify(deleteTeamMatchChatPayload));
        break;
      case SocketActions.SendChat:
        if (!socket) break;
        const { payload: payloadChat } = <ActionType<any>>action;
        if (payloadChat.isTeam) {
          let payloads = {
            room: payloadChat.room,
            user_id: payloadChat.user_id,
            match_id: payloadChat.match_id,
            team_id: payloadChat.team_id,
            username: payloadChat.username,
            team: payloadChat.team,
            message: payloadChat.message,
            image: payloadChat.image,
            time: payloadChat.time,
          };
          socket.emit(SocketMessages.TeamChat, JSON.stringify(payloads));
        } else {
          let payloads = {
            room: payloadChat.room,
            user_id: payloadChat.user_id,
            username: payloadChat.username,
            team: payloadChat.team,
            message: payloadChat.message,
            image: payloadChat.image,
            time: payloadChat.time,
          };
          socket.emit(SocketMessages.RoomChat, JSON.stringify(payloads));
        }
        break;
      case SocketActions.CheckIn:
        if (!socket) break;
        const { payload: payloadCheckIn } = <
          ActionType<{
            room: string;
            teamId: number | any;
            user_id: number;
            singleCheckIn: boolean;
          }>
        >action;
        socket.emit(SocketMessages.CheckIn, payloadCheckIn);
        // api.dispatch(checkIn(payloadCheckIn.user_id));
        break;
      case SocketActions.CheckOut:
        if (!socket) break;
        const { payload: payloadCheckOut } = <
          ActionType<{
            room: string;
            teamId: number | any;
            user_id: number;
            singleCheckIn: boolean;
          }>
        >action;
        socket.emit(SocketMessages.CheckOut, payloadCheckOut);
        break;
      default:
        return next(action);
    }
  };
};

export default MatchSocketMiddleware;
