import React from 'react'
import { AvatarImage } from '../../../components/avatar-image'
import { AiOutlineCamera } from 'react-icons/ai'
import { OrganizationInfo } from './organization-info'

const OrganizationHeader = ({
    organizationData,
    handleFileChange,
    isLoading,
}: any) => {
    return (
        <div className='flex justify-evenly items-center gap-2 p-5 border'>
            <div className='group cursor-pointer flex relative w-[100px] aspect-square rounded-full overflow-hidden justify-center items-center '>
                {isLoading ? (
                    <p>Loading ...</p>
                ) : (
                    <AvatarImage size='xxl' source={organizationData.image} />
                )}

                <label className='cursor-pointer text-white  w-full bg-black bg-opacity-80 h-full absolute top-0 right-0 left-0 bottom-0 m-auto z-10 hidden group-hover:flex justify-center items-center  '>
                    <AiOutlineCamera />
                    <input
                        type='file'
                        accept='image/*'
                        onChange={handleFileChange}
                        className='hidden mr-12'
                    />
                </label>
            </div>
            <OrganizationInfo name={organizationData.name} state={organizationData.state} numberOfSchools={organizationData.schools?.length} />
            <div className='flex justify-evenly flex-[3] items-center '></div>
        </div>
    )
}

export default OrganizationHeader