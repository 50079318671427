import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  useGetTeamsNotInSeasonQuery,
  useViewSingleSeasonQuery,
} from '../../../../../../slices/seasonApiSlice';
import Pagination from '../../../../../../components/pagination';
import { Search } from '../../../../../../components/search/search';
import { Team } from '../../../../../../features/listview/users/model/user.model';
import { TeamModel } from '../../../../../../model/team.model';
import {
  CheckboxItem,
  DropdownMenuFilter,
} from '../../../../../../components/ui/dropdown-menu/DropdownMenuFIlter';
import { allStates } from '../../../../../../utils/constants';

export function CheckboxAddTeamsSeason({
  chosenTeam,
  setChosenTeam,
  seasonStates,
}: {
  chosenTeam: any;
  setChosenTeam: Function;
  seasonStates: string[];
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const { data: seasonData } = useViewSingleSeasonQuery(params.id);
  const [allTeams, setAllTeams] = useState<string[]>();
  const [teamsNotInSeason, setTeamsNotInSeason] = useState<string[]>([]);
  const filterList = [{ title: 'name', property: 'optionName' }];
  const [searchQuery, setSearchQuery] = useState(filterList[0].title);
  const [teamFilterState, setTeamFilterState] = useState<string[]>([]);
  const { data, isLoading, isSuccess, isError } = useGetTeamsNotInSeasonQuery({
    id: seasonData.game_id,
    page: searchParams.get('page') ? searchParams.get('page') : 1,
    // game: searchParams.get('game') ? searchParams.get('game') : '',
    optionName: searchParams.get('optionName') ? searchParams.get('optionName') : '',
    season_id: seasonData.id,
    filterStates: teamFilterState,
    seasonStates: seasonStates,
  });
  const [search, setSearch] = useState({ by: 'optionName', keyword: '' });
  useEffect(() => {
    setSearchParams({ optionName: search.keyword });
  }, [searchQuery]);

  useEffect(() => {
    if (!data?.data) return;

    const arr: any = [];
    data.data.forEach((t: any) => {
      const { id, image } = t;
      const parsedTeam = new TeamModel(t);
      arr.push(
        JSON.stringify({
          id,
          teamName: parsedTeam.getOptionName(),
          image,
          state: parsedTeam.state,
        }),
      );
    });

    const set = new Set<string>(arr);
    setAllTeams([...set]);
  }, [data?.data]);

  useEffect(() => {
    if (!seasonData?.teams || !allTeams) return;

    // Filter teams that are not in tData.teams
    const teamsNotInSeason = allTeams.filter((teamString: string) => {
      const team = JSON.parse(teamString);
      return !seasonData.teams.some((seasonTeam: { id: number }) => seasonTeam.id === team.id);
    });

    setTeamsNotInSeason(teamsNotInSeason);
  }, [seasonData?.teams, allTeams]);
  function handleTeamFilterStateChange(v: CheckboxItem[]) {
    setTeamFilterState(v.filter((s) => s.defaultChecked).map((s) => s.name!));
  }
  const inputProps = {
    mainTitle: 'Tournament Information',
    name: 'format_id',
    label: 'Match Format',
    type: 'text',
    placeholder: 'Match format',
    isTrue: true,
    isSelect: true,
    options: [],
  };

  if (isLoading) return <p>Loading...</p>;
  return (
    <div
      className='flex pt-5 flex-col gap-5 justify-between flex-1'
      style={{ marginLeft: '30px', marginRight: '30px' }}
    >
      <div className='flex flex-col gap-5'>
        {/* <h2 className='font-bold'>Teams</h2> */}
        <div className='flex gap-5 items-center justify-between'>
          <div className='flex gap-3 items-center'>
            <input
              type='checkbox'
              checked={teamsNotInSeason?.every((i: any) =>
                chosenTeam.find((t: any) => t.id === JSON.parse(i).id),
              )}
              onClick={() =>
                allTeams?.every((i: any) => chosenTeam.find((t: any) => t.id === JSON.parse(i).id))
                  ? setChosenTeam([
                      ...chosenTeam.filter(
                        (t: any) => !allTeams?.find((d: any) => JSON.parse(d).id === t.id),
                      ),
                    ])
                  : setChosenTeam([
                      ...chosenTeam.filter(
                        (t: any) => !allTeams?.find((d: any) => JSON.parse(d).id === t.id),
                      ),
                      ...data.data,
                    ])
              }
              className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
            />
            Add All Teams
            <DropdownMenuFilter
              width={'w-full'}
              onChange={handleTeamFilterStateChange}
              items={allStates.map((s: any, i) => {
                return {
                  label: s.name,
                  id: i,
                  defaultChecked: teamFilterState?.includes(s.id),
                  name: s.name,
                } as CheckboxItem;
              })}
              onClear={() => setTeamFilterState([])}
              title='State'
            />
          </div>

          <Search
            setQuery={setSearchQuery}
            filterList={filterList}
            search={search}
            setSearch={setSearch}
          />
        </div>
        {allTeams
          ?.map((team) => JSON.parse(team))
          .map((t: Team, i: number) => {
            // const t: any=a
            // if(allTeams?.every((i: any) =>
            // a.id === JSON.parse(i).id))
            return (
              <label key={i} className='cursor-pointer'>
                <div
                  className={`flex gap-2  items-center border rounded rounded-xl p-2 ${
                    chosenTeam.find((i: any) => i.id === t.id) ? 'bg-gray-300' : ''
                  }`}
                >
                  <input
                    type='checkbox'
                    className='hidden'
                    onClick={() =>
                      chosenTeam.find((i: any) => i.id === t.id)
                        ? setChosenTeam((s: any) => s.filter((a: any) => a.id !== t.id))
                        : setChosenTeam((s: any) => [...s, t])
                    }
                    checked={Boolean(chosenTeam.find((i: any) => i.id === t.id))}
                  />
                  <div
                    className='flex gap-1 items-center p-2'
                    style={{ textTransform: 'capitalize' }}
                  >
                    {new TeamModel(t).getOptionName()}
                  </div>
                </div>
              </label>
            );
          })}
      </div>
      <div>
        <Pagination length={data?.data?.length} {...data?.meta} />
      </div>
    </div>
  );
}
