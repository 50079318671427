import { IoBody } from 'react-icons/io5';
import { apiSlice } from '../api/apiSlice';
import { ManualGenerateMatchDTO, UpdateMatchDTO } from '../utils/custom-types';
import {
  initCalendar,
  initChatMessages,
  initTeamChatMessages,
  pushChatMessage,
  pushTeamChatMessage,
  pushToCalendar,
  setMatchData,
  setReschedules,
} from './matchSlice';

export const matchApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getScreenshotsByMatchGameId: builder.query({
      query: (id: string | number) => ({
        url: `/match/screenshots/${id}`,
        method: 'GET',
      }),
      providesTags: ['Screenshot'],
    }),
    deleteMatchGame: builder.mutation({
      query: (id: string | number) => ({
        url: `/match/match-game/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SingleMatchInfo'],
    }),
    addMatchToWeek: builder.mutation({
      query: (arg: {
        id: string;
        body: { home_team_id: number; away_team_id?: number; match_date: string };
      }) => ({
        url: `/match/add-to-week/${arg.id}`,
        method: 'PATCH',
        body: arg.body,
      }),
      invalidatesTags: ['SeasonWeek', 'SingleSeasonInfo', 'Match'],
    }),
    checkIfMatchCanBeDeleted: builder.query({
      query: (id: string | number) => ({
        url: `/match/can-delete/${id}`,
        method: 'GET',
      }),
    }),
    deleteMatch: builder.mutation({
      query: (id: string | number) => ({
        url: `match/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SeasonWeek', 'Match', 'Seasons', 'SingleSeasonInfo'],
    }),
    cancelMatch: builder.mutation({
      query: (id: string | number) => ({
        url: `match/cancel-match/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['SeasonWeek', 'Match', 'Seasons', 'Tournaments', 'SingleTournament'],
    }),
    uncancelMatch: builder.mutation({
      query: (id: string | number) => ({
        url: `match/uncancel-match/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['SeasonWeek', 'Match', 'Seasons', 'Tournaments', 'SingleTournament'],
    }),
    rearrange: builder.mutation({
      query: ({
        body,
        weekId,
      }: {
        body: {
          first: { id: number; matchId: number; team: 'home' | 'away' };
          second: { id: number; matchId: number; team: 'home' | 'away' };
        };
        weekId: number;
      }) => ({
        url: '/match/rearrange',
        method: 'PATCH',
        body,
      }),
      async onQueryStarted(arg, api) {
        const { dispatch, queryFulfilled } = api;
        const patchResult = dispatch(
          matchApiSlice.util.updateQueryData(
            'getAllMatchesByWeekId',
            arg.weekId,
            (draft: {
              data: {
                id: number;
                home_team: [{ id: number; teamName: string }];
                away_team: [{ id: number; teamName: string }];
              }[];
              meta: any;
            }) => {
              const firstProp = arg.body.first.team === 'home' ? 'home_team' : 'away_team';
              const secondProp = arg.body.second.team === 'home' ? 'home_team' : 'away_team';

              const firstTeam = draft.data.filter((d) => {
                return d[firstProp][0].id === arg.body.first.id && d.id === arg.body.first.matchId;
              })?.[0];
              const [firstId, firstName] = [
                firstTeam[firstProp][0].id,
                firstTeam[firstProp][0].teamName,
              ];

              const secondTeam = draft.data.filter((d) => {
                return (
                  d[secondProp][0].id === arg.body.second.id && d.id === arg.body.second.matchId
                );
              })?.[0];
              const [secondId, secondName] = [
                secondTeam[secondProp][0].id,
                secondTeam[secondProp][0].teamName,
              ];

              // Object.assign(draft, {
              //   [secondProp]: [{ id: secondId, teamName: secondName }],
              //   [firstProp]: [{ id: firstId, teamName: firstName }],
              // });
              firstTeam[firstProp][0].id = secondId;
              firstTeam[firstProp][0].teamName = secondName;
              secondTeam[secondProp][0].id = firstId;
              secondTeam[secondProp][0].teamName = firstName;
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Match', 'Team', 'SingleSeasonInfo'],
    }),
    editGameResult: builder.mutation({
      query: ({
        id,
        body,
      }: {
        id: number;
        body: { ht_result: number; aw_result: number; oldAScore: number; oldHScore: number };
      }) => ({
        url: `/match/game-result/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SingleMatchInfo'],
    }),
    streamingUpdate: builder.mutation({
      query: ({ status, matchId }) => ({
        url: `/match/stream/${matchId}?status=${status}`,
        method: 'POST',
      }),
    }),
    submitMatchResult: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/submit-result/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SingleMatchInfo'],
    }),
    closeGameMatch: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/close-game-match/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SingleMatchInfo', 'Match'],
    }),
    sendGameDispute: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/game-dispute/${id}`,
        method: 'POST',
        body,
      }),
    }),
    createMatchChatDispute: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/match-chat-dispute/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MatchChatDispute'],
    }),
    getAllMatchChatDisputes: builder.query({
      query: (params: { page?: number; limit?: number; filters?: string }) => ({
        url: `/match/match-chat-dispute/all`,
        method: 'GET',
        params,
      }),
      providesTags: ['MatchChatDispute'],
    }),
    getMatchChatDisputeById: builder.query({
      query: ({ disputeId }) => ({
        url: `/match/match-chat-dispute/${disputeId}`,
        method: 'GET',
      }),
      providesTags: ['RescheduleHistory', 'Match', 'MatchChatDispute'],
    }),
    resolveMatchChatDispute: builder.mutation({
      query: ({
        matchId,
        body,
      }: {
        matchId: string;
        body: { disputeId: number; resolveAll: boolean };
      }) => ({
        url: `/match/resolve-match-chat-dispute/${matchId}`,
        method: 'PUT',
        body,
      }),
    }),
    sendGameMatchScreenshot: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/game-screenshot/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SingleMatchInfo'],
    }),
    endMatch: builder.mutation({
      query: (body) => ({
        url: '/match/end-match',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SingleMatchInfo', 'SingleSeasonInfo'],
    }),
    startMatch: builder.mutation({
      query: (id) => ({
        url: `/match/start-match/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['SingleMatchInfo', 'Match'],
    }),
    createGameMatch: builder.mutation({
      query: (id) => ({
        url: `/match/match-game/${id}`,
        method: 'POST',
      }),
    }),
    initTeamChatMessages: builder.query({
      query: ({ id, page, team, init }) => ({
        url: `/match/team-chat/${id}?page=${page ? page : 1}&team=${team}`,
        method: 'GET',
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch, getCacheEntry }) {
        console.log('inside po thirret');
        await cacheDataLoaded;
        const entries = getCacheEntry();
        if (arg.page && !arg.init) dispatch(pushTeamChatMessage(entries.data.data));
        else dispatch(initTeamChatMessages(entries.data.data));
      },
    }),
    initChatMessages: builder.query({
      query: ({ id, page }) => ({
        url: `/match/chat/${id}?page=${page ? page : 1}`,
        method: 'GET',
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch, getCacheEntry }) {
        await cacheDataLoaded;
        const entries = getCacheEntry();
        if (arg.page) dispatch(pushChatMessage(entries.data.data));
        else dispatch(initChatMessages(entries.data.data));
      },
    }),

    forfeitMatch: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/forfeit/${id}`,
        method: 'Post',
        body,
      }),

      invalidatesTags: [
        'SingleMatchInfo',
        'Match',
        'SeasonStanding',
        'TournamentStanding',
        'Tournaments',
        'SingleTournament',
      ],
    }),
    doubleForfeitMatch: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/double-forfeit/${id}`,
        method: 'Post',
        body,
      }),
      invalidatesTags: ['SingleMatchInfo', 'Match', 'SeasonStanding'],
    }),
    unForfeitDoubleMatch: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/double-unforfeit/${id}`,
        method: 'Post',
        body: body,
      }),
      invalidatesTags: ['SingleMatchInfo', 'Match', 'SeasonStanding'],
    }),
    updateMatchResult: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/result/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SingleMatchInfo', 'Match'],
    }),
    updateSingleMatchInfo: builder.mutation({
      query: ({ id, body }: { id: number | string; body: UpdateMatchDTO }) => ({
        url: `/match/info/${id}`,
        method: 'put',
        body,
      }),

      invalidatesTags: ['SingleMatchInfo', 'Match', 'Team'],
    }),
    getSingleMatchInfo: builder.query({
      query: (id) => ({
        url: `/match/info/${id}`,
        method: 'get',
      }),

      async onQueryStarted(arg, api) {
        const data = await api.queryFulfilled;
        api.dispatch(setMatchData(data.data));
      },
      // async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch, getCacheEntry }) {
      //   await cacheDataLoaded;
      //   const entries = getCacheEntry();
      //   console.log('cache', cacheDataLoaded, entries);
      //   dispatch(setMatchData(entries.data));
      // },
      providesTags: ['SingleMatchInfo', 'Match', 'Team', 'MatchChatDispute'],
    }),
    updateTournamentMatches: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/update/${id}`,
        method: 'put',
        body,
      }),
      invalidatesTags: ['Match'],
    }),
    // createMatch: builder.mutation({
    //   query: (body) => ({
    //     url: '/match/create',
    //     method: 'Post',
    //     body,
    //   }),
    //   invalidatesTags: ['Match'],
    // }),
    // getAllMatches: builder.query({
    //   query: () => ({
    //     url: '/match/all',
    //     method: 'Get',
    //   }),
    //   providesTags: ['Match'],
    // }),
    getAllMatchesByTournament: builder.query({
      query: ({ id, query }) => ({
        url: `/match/matches-by-tournament/${id}?page=${query}`,
        method: 'Get',
      }),
      providesTags: ['Match'],
    }),
    getAllMatchesByTournamentId: builder.query({
      query: (id) => ({
        url: `/match/by-tournament/${id}`,
        method: 'Get',
      }),
      providesTags: ['Match', 'Team'],
    }),
    manualBracketGenerator: builder.mutation({
      query: (body) => ({
        url: '/match/manual-generator',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['Match', 'SingleTournament'],
    }),
    manualGenerateMatch: builder.mutation({
      query: (body: ManualGenerateMatchDTO) => ({
        url: '/match/manual-match',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['Match', 'Team'],
    }),
    getAllMatchesByWeekId: builder.query({
      query: (id) => ({
        url: `/match/by-season-week/${id}`,
        method: 'Get',
      }),
      providesTags: ['Match', 'Team', 'SingleSeasonInfo'],
    }),

    createReschedule: builder.mutation({
      query: (body) => ({
        url: '/match/reschedule/create',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['Reschedules', 'Match', 'SingleMatchInfo'],
    }),
    getAllReschedulesByMatchId: builder.query({
      query: (id) => ({
        url: `/match/reschedule/${id}`,
        method: 'Get',
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch, getCacheEntry }) {
        await cacheDataLoaded;
        const entries = getCacheEntry();
        dispatch(setReschedules(entries.data));
      },

      providesTags: ['Reschedules', 'Match'],
    }),
    deleteReschedule: builder.mutation({
      query: (body) => ({
        url: '/match/reschedule',
        method: 'DELETE',
        body: { id: body.id },
      }),
      invalidatesTags: ['Reschedules', 'Match'],
    }),
    getAllUpcomingMatchesByDate: builder.mutation({
      query: ({ body, query }) => ({
        url: `/match/up-coming-matches-by-date?${query.query}=${query.param}${
          query.limit ? `&limit=${query.limit}` : ''
        }`,
        method: 'Post',
        body,
      }),
      invalidatesTags: ['Match'],
    }),
    approveReschedule: builder.mutation({
      query: (body) => ({
        url: '/match/approve-reschedule',
        method: 'Put',
        body,
      }),
      invalidatesTags: ['Match', 'SingleMatchInfo', 'Reschedules'],
    }),
    getAllMatchesByDateStatus: builder.query({
      query: ([query, param]: [
        query: {
          name?: string | null | undefined;
          date?: string | null | undefined;
          page?: string | number | undefined | null;
          limit?: number | undefined | null;
          team?: number | null;
        },
        param: { dateStatus: number | string },
      ]) => ({
        url: `/match/by-date/${param.dateStatus}`,
        method: 'Get',
        params: query,
      }),
      providesTags: ['Match'],
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch, getCacheEntry }) {
        await cacheDataLoaded;
        const entries = getCacheEntry();
        if (arg[0].page !== 1)
          dispatch(pushToCalendar({ date: arg[0].date, data: entries.data.data }));
        else dispatch(initCalendar({ date: arg[0].date, data: entries.data.data }));
      },
    }),
    sendToNextRoundTheWinnerTeam: builder.mutation({
      query: (body) => ({
        url: '/match/send-to-next-round',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['SingleTournament', 'Team'],
    }),
    getWinnerOfTournament: builder.query({
      query: (id) => ({
        url: `/match/winner-of-tournament/${id}`,
        method: 'Get',
      }),
      providesTags: ['SingleTournament', 'Match', 'Team'],
    }),
    getAllScreenshotByUserGameId: builder.query({
      query: (id) => ({
        url: `/match/game-screenshot/${id}`,
        method: 'Get',
      }),
      providesTags: ['Screenshot', 'Match', 'SingleMatchInfo'],
    }),
    getAllScreenshotsByMatchId: builder.query({
      query: (id) => ({
        url: `/match/match-screenshot/${id}`,
        method: 'Get',
      }),
      providesTags: ['Screenshot', 'Match', 'SingleMatchInfo'],
    }),
    updateScreenshot: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/update/game-screenshot/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['Screenshot'],
    }),
    updateMatchTeam: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/update-team/${id}`,
        method: 'Put',
        body,
      }),
      invalidatesTags: ['Match', 'Team'],
    }),
    getMatchRescheduleHistoryById: builder.query({
      query: (id) => ({
        url: `/match/history/${id}`,
        method: 'GET',
      }),
      providesTags: ['RescheduleHistory', 'Match'],
    }),
    editRescheduleDate: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/reschedule/edit/${id}`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['RescheduleHistory', 'SingleMatchInfo'],
    }),
    canEditReschedule: builder.query({
      query: (id) => ({
        url: `/match/reschedule/can-edit/${id}`,
        method: 'GET',
      }),
      providesTags: ['RescheduleHistory', 'Match'],
    }),
    unForfeitMatch: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/${id}/unforfeit`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['SingleMatchInfo', 'Match', 'SeasonStanding', 'SingleTournament'],
    }),
    finalDeleteMatch: builder.mutation({
      query: (id) => ({
        url: `/match/final/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SingleMatchInfo', 'SeasonStanding', 'SeasonWeek', 'Match', 'Seasons'],
    }),
    editMatchScore: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/${id}/edit-scores`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['SingleMatchInfo', 'Match', 'SeasonStanding', 'SingleTournament'],
    }),
    editClosedGameMatch: builder.mutation({
      query: ({ id, body }) => ({
        url: `/match/edit-closed/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['SingleMatchInfo', 'Match'],
    }),
    getMatchForfeitHistoryById: builder.query({
      query: (id) => ({
        url: `/match/forfeit-history/${id}`,
        method: 'GET',
      }),
      providesTags: ['Match'],
    }),
    getAllMatchesByTournamentRound: builder.query({
      query: (match_id) => ({
        url: `/match/by-tournamentround/${match_id}`,
        method: 'Get',
      }),
      providesTags: ['SingleMatchInfo', 'Match', 'SingleTournament'],
    }),
    getCanRescheduleMatchByMatchId: builder.query({
      query: (match_id) => ({
        url: `/match/can-reschedule/${match_id}`,
        method: 'Get',
      }),
      providesTags: ['SingleMatchInfo', 'Match', 'SingleTournament'],
    }),
    getCanSubmitGameScreenshot: builder.query({
      query: (match_id) => ({
        url: `/match/can-submit-screenshot/${match_id}`,
        method: 'Get',
      }),
      providesTags: ['SingleMatchInfo', 'Match', 'SingleTournament'],
    }),
  }),
});

export const {
  useSendGameDisputeMutation,
  useCreateMatchChatDisputeMutation,
  useGetAllMatchChatDisputesQuery,
  useGetMatchChatDisputeByIdQuery,
  useResolveMatchChatDisputeMutation,
  useStreamingUpdateMutation,
  useSendToNextRoundTheWinnerTeamMutation,
  useSubmitMatchResultMutation,
  useCloseGameMatchMutation,
  useSendGameMatchScreenshotMutation,
  useEndMatchMutation,
  useStartMatchMutation,
  useCreateGameMatchMutation,
  useInitTeamChatMessagesQuery,
  useLazyInitTeamChatMessagesQuery,
  useInitChatMessagesQuery,
  useForfeitMatchMutation,
  useUpdateMatchResultMutation,
  useManualGenerateMatchMutation,
  useLazyGetAllMatchesByWeekIdQuery,
  useUpdateSingleMatchInfoMutation,
  useGetSingleMatchInfoQuery,
  useLazyGetSingleMatchInfoQuery,
  useUpdateTournamentMatchesMutation,
  // useCreateMatchMutation,
  // useGetAllMatchesQuery,
  useGetAllMatchesByTournamentIdQuery,
  useManualBracketGeneratorMutation,
  useGetAllMatchesByWeekIdQuery,
  useGetAllReschedulesByMatchIdQuery,
  useCreateRescheduleMutation,
  useDeleteRescheduleMutation,
  useApproveRescheduleMutation,
  useGetAllUpcomingMatchesByDateMutation,
  useGetAllMatchesByDateStatusQuery,
  useGetAllMatchesByTournamentQuery,
  useGetWinnerOfTournamentQuery,
  useEditGameResultMutation,
  useGetAllScreenshotByUserGameIdQuery,
  useGetAllScreenshotsByMatchIdQuery,
  useUpdateScreenshotMutation,
  useRearrangeMutation,
  useUpdateMatchTeamMutation,
  useCheckIfMatchCanBeDeletedQuery,
  useDeleteMatchMutation,
  useCancelMatchMutation,
  useUncancelMatchMutation,
  useAddMatchToWeekMutation,
  useGetMatchRescheduleHistoryByIdQuery,
  useEditRescheduleDateMutation,
  useCanEditRescheduleQuery,
  useFinalDeleteMatchMutation,
  useEditMatchScoreMutation,
  useUnForfeitMatchMutation,
  useEditClosedGameMatchMutation,
  useDeleteMatchGameMutation,
  useGetScreenshotsByMatchGameIdQuery,
  useDoubleForfeitMatchMutation,
  useUnForfeitDoubleMatchMutation,
  useGetMatchForfeitHistoryByIdQuery,
  useLazyGetMatchForfeitHistoryByIdQuery,
  useGetAllMatchesByTournamentRoundQuery,
  useGetCanRescheduleMatchByMatchIdQuery,
  useGetCanSubmitGameScreenshotQuery,
} = matchApiSlice;
