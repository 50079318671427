import { MatchStatus } from '../../../utils/constants';

export const matchStatusRules = {
  forfeit: [MatchStatus.InProgress],
  checkin: [
    MatchStatus.Upcoming,
    MatchStatus.RescheduleRequested,
    MatchStatus.UpcomingRescheduledFor,
  ],
  start: [
    MatchStatus.Upcoming,
    MatchStatus.Overdue,
    MatchStatus.RescheduleRequested,
    MatchStatus.UpcomingRescheduledFor,
    MatchStatus.Benched,
  ],
  reschedule: [
    MatchStatus.Upcoming,
    MatchStatus.RescheduleRequested,
    MatchStatus.UpcomingRescheduledFor,
    MatchStatus.Overdue,
  ],
  live: [MatchStatus.InProgress],
  createGame: [MatchStatus.InProgress, MatchStatus.Completed],
};
