import { useCallback, useEffect } from 'react';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { RiNotification3Line, RiTeamLine as Team } from 'react-icons/ri';
import { MdOutlineSchool as School } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useStateContext } from '../contexts/ContextProvider';
import { selectFirstName, logOut as logout, selectUser, changeLogOut } from '../slices/auth';
import Notification from './Notification';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './ui/dropdown-menu/DropdownMenu';
import { Button } from './ui/button/Button';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar/Avatar';
import { CiSettings as Settings } from 'react-icons/ci';
import { FiLogOut as Logout } from 'react-icons/fi';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover/Popover';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip/Tooltip';
import { AppDispatch } from '../store';
import { firstLetterToUpperCase, wordWithFirstLetterToUpperCase } from '../utils/utils';
import { useNavigate } from 'react-router-dom';
import { useGetUnseenNotificationQuery } from '../slices/notificationApiSlice';
import CanIView from './can-i-view/can-i-view';
import { apiSlice } from '../api/apiSlice';
import helpcenter from '../assets/helpcenter.svg';
import { useRefreshUserDataQuery } from '../slices/userApiSlice';

function Navbar() {
  const { activeMenu, setActiveMenu, screenSize, setScreenSize } = useStateContext();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [setScreenSize]);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize, setActiveMenu]);

  const user = useSelector(selectUser);
  const { data, isLoading } = useGetUnseenNotificationQuery(user.id, {
    refetchOnMountOrArgChange: true,
  });
  const refresh = useRefreshUserDataQuery('');

  const handleActiveMenu = () => setActiveMenu(!activeMenu);
  const firstName = useSelector(selectFirstName);
  const dispatch = useDispatch<AppDispatch>();

  const logOut = useCallback(() => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(logout());
  }, [dispatch]);

  return (
    <div className='flex h-18 justify-between p-2 relative  bg-white '>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild onClick={handleActiveMenu}>
            <Button variant='ghost' className='w-10 p-0 ml-1'>
              {activeMenu ? (
                <>
                  <AiOutlineMenuFold className='h-6 w-6' color='red' />
                  <span className='sr-only'>Collapse</span>
                </>
              ) : (
                <>
                  <AiOutlineMenuUnfold className='h-6 w-6' color='red' />
                  <span className='sr-only'>Expand</span>
                </>
              )}
            </Button>
          </TooltipTrigger>
          <TooltipContent>{activeMenu ? <p>Collapse Menu</p> : <p>Expand Menu</p>}</TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <div className='flex items-center gap-5'>
        <div className={`notification-${data?.unseen ? 'show' : ''}`} attr-count={data?.unseen}>
          <Popover>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <PopoverTrigger asChild>
                    <Button variant='outline' className='w-10 rounded-full p-0'>
                      <RiNotification3Line className='h-4 w-4' />
                      {/* <Indicator  /> */}
                    </Button>
                  </PopoverTrigger>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Notifications</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <PopoverContent className='w-70'>
              <Notification />
            </PopoverContent>
          </Popover>
        </div>
        <div className='ml-auto mr-4 flex items-center gap-2'>
          <h3 className='text-sm font-semibold'>
            Hi, {user?.username ? wordWithFirstLetterToUpperCase(user?.username) : ''}
          </h3>
        </div>
        <div className='ml-auto mr-2'>
          <DropdownMenu>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <DropdownMenuTrigger asChild>
                    <Button variant='ghost' className='relative h-10 w-10 mt-1 mr-2 rounded-full'>
                      <Avatar>
                        <AvatarImage src={user.image} alt={`@${user?.username}`} />
                        <AvatarFallback>
                          {firstLetterToUpperCase(user.firstName) +
                            firstLetterToUpperCase(user.lastName)}
                        </AvatarFallback>
                      </Avatar>
                    </Button>
                  </DropdownMenuTrigger>
                </TooltipTrigger>
                <TooltipContent>
                  <p>My Account</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <DropdownMenuContent className='w-48' align='end' forceMount>
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <CanIView component='profile.School'>
                  <DropdownMenuItem onClick={() => navigate('/schools')}>
                    <School className='mr-2 h-4 w-4' />
                    <span>School</span>
                  </DropdownMenuItem>
                </CanIView>
                <CanIView component='profile.Team'>
                  <DropdownMenuItem onClick={() => navigate('/teams')}>
                    <Team className='mr-2 h-4 w-4' />
                    <span>Teams</span>
                  </DropdownMenuItem>
                </CanIView>
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                {/* <DropdownMenuItem>
                  <Profile className='mr-2 h-4 w-4' />
                  <span>Profile</span>
                </DropdownMenuItem> */}
                <DropdownMenuItem onClick={() => navigate('/settings')}>
                  <Settings className='mr-2 h-4 w-4' />
                  <span>Settings</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
              {/* <DropdownMenuGroup>
                <CanIView component='profileSupport'>
                  <DropdownMenuItem onClick={() => navigate('/support')}>
                    <img src={helpcenter} className='mr-2 h-4 w-4' />
                    <span>Support</span>
                  </DropdownMenuItem>
                </CanIView>
              </DropdownMenuGroup>
              <DropdownMenuSeparator /> */}
              <DropdownMenuGroup>
                <DropdownMenuItem onClick={() => dispatch(changeLogOut(true))}>
                  <Logout className='mr-2 h-4 w-4' />
                  <span>Logout</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
