import * as yup from 'yup';
import {
  lastnameRulesHint, nameRulesHint,
  nameRulesWithSpecialCharacter
} from '../../features/authentication/yupschema';
export const EditUserSchema = yup.object().shape({
  firstName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required'),
  lastName: yup.string().matches(nameRulesWithSpecialCharacter, lastnameRulesHint).required('Required'),
  tags: yup.string().nullable(),
  // roles: yup.string().required('Required'),
  status: yup.string().nullable(),
  eligibilityStatus: yup.string().nullable(),
});
